import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import GlobalStyle from './styles/GlobalStyle';
import theme from './styles/theme';

// Components
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LoadingScreen from './components/LoadingScreen';

// Pages
import Home from './pages/Home';
import About from './pages/About';
import Resume from './pages/Resume';
import Projects from './pages/Projects';
import Contact from './pages/Contact';

function App() {
  const [loading, setLoading] = useState(true);
  
  // Simulate minimum loading time
  useEffect(() => {
    // Ensure the loading screen shows for at least 3.5 seconds
    const minLoadingTime = setTimeout(() => {
      // Ready to allow the user to enter the site
    }, 3500);
    
    return () => clearTimeout(minLoadingTime);
  }, []);
  
  const handleEnterSite = () => {
    setLoading(false);
  };
  
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {loading ? (
        <LoadingScreen onComplete={handleEnterSite} />
      ) : (
        <Router>
          <Navbar />
          <AnimatePresence mode='wait'>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/resume' element={<Resume />} />
              <Route path='/projects' element={<Projects />} />
              <Route path='/contact' element={<Contact />} />
            </Routes>
          </AnimatePresence>
          <Footer />
        </Router>
      )}
    </ThemeProvider>
  );
}

export default App;
