import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  padding: ${({ theme }) => theme.spacing['2xl']} 0;
  margin-top: ${({ theme }) => theme.spacing['3xl']};
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.md};
`;

const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xl};
  }
`;

const FooterSection = styled.div`
  flex: 1;
  max-width: 300px;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 100%;
  }
`;

const SectionTitle = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 40px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

const SectionContent = styled.div`
  color: ${({ theme }) => theme.colors.textLight};
`;

const FooterLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FooterLink = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  
  a {
    color: ${({ theme }) => theme.colors.textLight};
    transition: ${({ theme }) => theme.transitions.default};
    
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
      padding-left: 5px;
    }
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const SocialIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.textLight};
  transition: ${({ theme }) => theme.transitions.default};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.background};
    transform: translateY(-3px);
  }
`;

const Divider = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  margin: ${({ theme }) => theme.spacing.md} 0;
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.md};
    text-align: center;
  }
`;

const Copyright = styled.p`
  color: ${({ theme }) => theme.colors.textLight};
  margin: 0;
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <FooterContainer>
      <FooterContent>
        <FooterTop>
          <FooterSection>
            <SectionTitle>About Me</SectionTitle>
            <SectionContent>
              <p>PhD student, Microsoft Software Engineer / Machine Learning Engineer, and Professor of Computer Science passionate about technology and education.</p>
              <SocialLinks>
                <SocialIcon href="https://www.linkedin.com/in/robert-moseley-cs/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin />
                </SocialIcon>
                <SocialIcon href="https://github.com/" target="_blank" rel="noopener noreferrer">
                  <FaGithub />
                </SocialIcon>
                <SocialIcon href="mailto:robby.moseley@gmail.com">
                  <FaEnvelope />
                </SocialIcon>
              </SocialLinks>
            </SectionContent>
          </FooterSection>
          
          <FooterSection>
            <SectionTitle>Quick Links</SectionTitle>
            <SectionContent>
              <FooterLinks>
                <FooterLink><Link to="/">Home</Link></FooterLink>
                <FooterLink><Link to="/about">About</Link></FooterLink>
                <FooterLink><Link to="/resume">Resume</Link></FooterLink>
                <FooterLink><Link to="/contact">Contact</Link></FooterLink>
              </FooterLinks>
            </SectionContent>
          </FooterSection>
          
          <FooterSection>
            <SectionTitle>Contact Info</SectionTitle>
            <SectionContent>
              <p>Feel free to reach out to me for collaborations or just to say hi!</p>
              <p>Email: robby.moseley@gmail.com</p>
              <p>Location: Riverside, CA</p>
            </SectionContent>
          </FooterSection>
        </FooterTop>
        
        <Divider />
        
        <FooterBottom>
          <Copyright>© {currentYear} Robert Moseley. All rights reserved. ↑ ↑ ↓ ↓ ← → ← → B A.</Copyright>
          <div>
            <Link to="/privacy-policy" style={{ marginRight: '1rem', color: 'inherit' }}>
              Privacy Policy
            </Link>
            <Link to="/terms-of-service" style={{ color: 'inherit' }}>
              Terms of Service
            </Link>
          </div>
        </FooterBottom>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
