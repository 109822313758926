const theme = {
  colors: {
    primary: '#333333',     // Dark gray (primary accent)
    secondary: '#666666',   // Medium gray (secondary accent)
    accent: '#000000',      // Black (strong accent)
    background: '#FFFFFF',  // White
    backgroundDark: '#F2F2F2', // Light gray
    backgroundAlt: '#EBEBEB', // Slightly darker light gray
    text: '#111111',        // Nearly black
    textLight: '#777777',   // Medium gray for secondary text
    border: '#DDDDDD',      // Light gray for borders
    success: '#444444',     // Dark gray for success states
    error: '#999999',       // Medium gray for error states
    neutral1: '#F7F7F7',    // Very light gray
    neutral2: '#E0E0E0',    // Light gray alternative
    neutral3: '#F9F9F9',    // Another very light gray
  },
  fonts: {
    primary: "'Inter', sans-serif",
    secondary: "'Space Grotesk', sans-serif",
    tertiary: "'Roboto Mono', monospace",
    code: "'JetBrains Mono', monospace",
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.25rem',
    xl: '1.5rem',
    '2xl': '2rem',
    '3xl': '2.5rem',
    '4xl': '3.5rem',
    '5xl': '4.5rem',
    '6xl': '6rem',
  },
  spacing: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem',
    '2xl': '3rem',
    '3xl': '4rem',
    '4xl': '6rem',
  },
  borderRadius: {
    sm: '0.125rem',
    md: '0.25rem',
    lg: '0.5rem',
    xl: '1rem',
    full: '9999px',
  },
  shadows: {
    sm: '0 1px 2px 0 rgba(0, 0, 0, 0.03)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.03)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.05), 0 4px 6px -2px rgba(0, 0, 0, 0.02)',
    xl: '0 20px 25px -5px rgba(0, 0, 0, 0.03), 0 10px 10px -5px rgba(0, 0, 0, 0.02)',
  },
  breakpoints: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },
  transitions: {
    default: 'all 0.3s cubic-bezier(0.16, 1, 0.3, 1)', // Sleek, futuristic easing
    fast: 'all 0.15s cubic-bezier(0.16, 1, 0.3, 1)',
    slow: 'all 0.5s cubic-bezier(0.16, 1, 0.3, 1)',
    bounce: 'all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1)', // Subtle bounce
    elastic: 'all 0.5s cubic-bezier(0.16, 1.36, 0.57, 0.96)', // Subtle elastic
  },
};

export default theme;
