import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&family=Playfair+Display:wght@400;500;600;700;800;900&family=DM+Sans:wght@400;500;700&family=Fira+Code:wght@400;500&display=swap');

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 16px;
    scroll-behavior: smooth;
  }

  body {
    font-family: ${props => props.theme.fonts.tertiary};
    color: ${props => props.theme.colors.text};
    background-color: ${props => props.theme.colors.background};
    line-height: 1.8;
    overflow-x: hidden;
    letter-spacing: 0.01em;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    letter-spacing: -0.02em;
  }

  h1 {
    font-size: ${props => props.theme.fontSizes['4xl']};
  }

  h2 {
    font-size: ${props => props.theme.fontSizes['3xl']};
  }

  h3 {
    font-size: ${props => props.theme.fontSizes['2xl']};
  }

  h4 {
    font-size: ${props => props.theme.fontSizes.xl};
  }

  h5 {
    font-size: ${props => props.theme.fontSizes.lg};
  }

  h6 {
    font-size: ${props => props.theme.fontSizes.md};
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    transition: ${props => props.theme.transitions.default};

    &:hover {
      color: ${props => props.theme.colors.secondary};
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  button, input, textarea, select {
    font-family: inherit;
  }

  button {
    cursor: pointer;
  }

  ul, ol {
    list-style-position: inside;
    margin-bottom: 1rem;
  }

  section {
    padding: ${props => props.theme.spacing['4xl']} 0;
    position: relative;
  }

  .container {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 ${props => props.theme.spacing.xl};
  }

  .section-title {
    text-align: center;
    margin-bottom: ${props => props.theme.spacing['3xl']};
    position: relative;
    font-size: ${props => props.theme.fontSizes['3xl']};
    font-weight: 900;
    
    &:after {
      content: '';
      display: block;
      width: 50px;
      height: 3px;
      background-color: ${props => props.theme.colors.primary};
      margin: 0.5rem auto 0;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    html {
      font-size: 14px;
    }

    section {
      padding: ${props => props.theme.spacing['2xl']} 0;
    }
  }
`;

export default GlobalStyle;
