import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaGraduationCap, FaBriefcase, FaCode, FaChalkboardTeacher, FaLightbulb } from 'react-icons/fa';
import robbyImage from '../assets/robby_watch.JPEG';

import Section from '../components/Section';
import Button from '../components/Button';



const PageHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  padding: 8rem 0 4rem;
  text-align: center;
  position: relative;
`;

const PageTitle = styled.h1`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  cursor: pointer;
  display: inline-block;
  position: relative;
`;

const PageDescription = styled.p`
  max-width: 700px;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.textLight};
  position: relative;
  z-index: 1;
`;

const AboutGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 500px) 1fr;
  gap: ${({ theme }) => theme.spacing.xl};
  align-items: start;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`;

const AboutImage = styled(motion.div)`
  width: 100%;
  max-width: 500px;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.lg};
  display: flex;
  justify-content: center;
  align-items: center;
  
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
  }
`;

const AboutContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const AboutText = styled.p`
  color: ${({ theme }) => theme.colors.textLight};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  line-height: 1.7;
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacing.md};
  margin: ${({ theme }) => theme.spacing.xl} 0;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

const StatItem = styled(motion.div)`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;

const StatNumber = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['3xl']};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

const StatLabel = styled.div`
  color: ${({ theme }) => theme.colors.textLight};
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

const TimelineContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.xl} 0;
`;

const TimelineItem = styled(motion.div)`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const TimelineIcon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  margin-right: ${({ theme }) => theme.spacing.lg};
  flex-shrink: 0;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

const TimelineContent = styled.div`
  flex: 1;
`;

const TimelineDate = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.textLight};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

const TimelineTitle = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

const TimelineSubtitle = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

const TimelineDescription = styled.p`
  color: ${({ theme }) => theme.colors.textLight};
`;

const SkillsContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.xl} 0;
`;

const SkillCategory = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

const CategoryTitle = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  display: flex;
  align-items: center;
  
  svg {
    margin-right: ${({ theme }) => theme.spacing.sm};
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const SkillsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${({ theme }) => theme.spacing.md};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const SkillItem = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  padding: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  text-align: center;
  transition: ${({ theme }) => theme.transitions.default};
  position: relative;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    transform: translateY(-5px);
  }
`;

const HiddenQuote = styled(motion.div)`
  width: 100%;
  margin: 0.5rem auto 0.75rem;
  max-width: 700px;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 5;
  
  .quote-inner {
    background-color: ${({ theme }) => theme.colors.backgroundLight};
    color: ${({ theme }) => theme.colors.text};
    padding: 0.75rem 1rem;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    margin: 0;
  }
  
  p {
    font-size: 1.1rem;
    font-style: italic;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  
  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;



const About = () => {
  const [showQuote, setShowQuote] = useState(false);
  const hoverTimerRef = useRef(null);
  
  const handleTitleMouseEnter = () => {
    hoverTimerRef.current = setTimeout(() => {
      setShowQuote(true);
    }, 3000); // 3 seconds hover
  };
  
  const handleTitleMouseLeave = () => {
    if (hoverTimerRef.current) {
      clearTimeout(hoverTimerRef.current);
      hoverTimerRef.current = null;
    }
    setShowQuote(false);
  };
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <PageHeader>
        <motion.div variants={itemVariants}>
          <PageTitle 
            onMouseEnter={handleTitleMouseEnter}
            onMouseLeave={handleTitleMouseLeave}
          >
            About Me
          </PageTitle>
          <AnimatePresence>
            {showQuote && (
              <HiddenQuote
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.8, ease: 'easeInOut' }}
              >
                <motion.div 
                  className="quote-inner"
                  initial={{ y: -15 }}
                  animate={{ y: 0 }}
                  exit={{ y: -15 }}
                  transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1] }}
                >
                  <p>
                    <FaLightbulb size={18} />
                    "Putting in the man-hours to study the science of what you need."
                  </p>
                </motion.div>
              </HiddenQuote>
            )}
          </AnimatePresence>
          <PageDescription>
            Learn more about my background, experience, and skills
          </PageDescription>
        </motion.div>
      </PageHeader>
      
      <Section>
        <AboutGrid>
          <AboutImage
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <img src={robbyImage} alt="Robert Moseley" />
          </AboutImage>
          
          <AboutContent
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <h2>My Journey</h2>
            <AboutText>
              Hello! I'm Robert Moseley, a PhD student, Microsoft Software Engineer, Machine Learning Engineer, and Professor of Computer Science with a passion for technology, education, and innovation.
            </AboutText>
            <AboutText>
              My journey in technology began with a fascination for how computers work and how they can be used to solve complex problems. This curiosity led me to pursue degrees in Computer Science and eventually a PhD at UC Riverside, where I'm researching cutting-edge approaches in Multimodal Machine Learning and Reinforcement Learning.
            </AboutText>
            <AboutText>
              In my professional career, I've had the privilege of working at Microsoft as a Software Engineer, where I've led development on CoPilot research projects and developed RAG architectures that reduced errors by 70% across 50 million inferences. Alongside my industry work, I've maintained a strong connection to academia as an Adjunct Professor at California Baptist University and Bakersfield College, where I enjoy teaching AI and programming courses to the next generation of computer scientists.
            </AboutText>
            
            <StatsContainer>
              <StatItem
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4 }}
                viewport={{ once: true }}
              >
                <StatNumber>5+</StatNumber>
                <StatLabel>Years in Industry</StatLabel>
              </StatItem>
              <StatItem
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4, delay: 0.1 }}
                viewport={{ once: true }}
              >
                <StatNumber>10+</StatNumber>
                <StatLabel>Projects Completed</StatLabel>
              </StatItem>
              <StatItem
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4, delay: 0.2 }}
                viewport={{ once: true }}
              >
                <StatNumber>3+</StatNumber>
                <StatLabel>Years Teaching</StatLabel>
              </StatItem>
            </StatsContainer>
            
            <Button to="/contact" style={{ alignSelf: 'flex-start' }}>
              Get In Touch
            </Button>
          </AboutContent>
        </AboutGrid>
      </Section>
      
      <Section title="Education & Experience" bgColor={({ theme }) => theme.colors.backgroundDark}>
        <TimelineContainer>
          <TimelineItem
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            <TimelineIcon>
              <FaGraduationCap />
            </TimelineIcon>
            <TimelineContent>
              <TimelineDate>2025 - Present</TimelineDate>
              <TimelineTitle>PhD in Computer Science</TimelineTitle>
              <TimelineSubtitle>University of California, Riverside</TimelineSubtitle>
              <TimelineDescription>
                Conducting research in Multimodal Machine Learning and Reinforcement Learning.
              </TimelineDescription>
            </TimelineContent>
          </TimelineItem>
          
          <TimelineItem
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            viewport={{ once: true }}
          >
            <TimelineIcon>
              <FaBriefcase />
            </TimelineIcon>
            <TimelineContent>
              <TimelineDate>August 2024 - Present</TimelineDate>
              <TimelineTitle>Software Engineer II</TimelineTitle>
              <TimelineSubtitle>Microsoft</TimelineSubtitle>
              <TimelineDescription>
                Leading development on Microsoft CoPilot research projects, utilizing RAG and chain-of-thought reasoning to synthesize data across millions of entries in the M365 knowledge graph. Developed RAG architecture that reduced errors by 70% across 50 million inferences.
              </TimelineDescription>
            </TimelineContent>
          </TimelineItem>
          
          <TimelineItem
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            viewport={{ once: true }}
          >
            <TimelineIcon>
              <FaChalkboardTeacher />
            </TimelineIcon>
            <TimelineContent>
              <TimelineDate>January 2024 - Present</TimelineDate>
              <TimelineTitle>Adjunct Professor of Computer Science / AI</TimelineTitle>
              <TimelineSubtitle>California Baptist University & Bakersfield College</TimelineSubtitle>
              <TimelineDescription>
                Teaching the penultimate course on artificial intelligence for undergraduate students at CBU. Instructing multiple sections of introductory programming courses at Bakersfield College, developing curriculum, lectures, and hands-on projects in Java, C++, and Python.
              </TimelineDescription>
            </TimelineContent>
          </TimelineItem>
          
          <TimelineItem
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            viewport={{ once: true }}
          >
            <TimelineIcon>
              <FaGraduationCap />
            </TimelineIcon>
            <TimelineContent>
              <TimelineDate>June 2023 - July 2024</TimelineDate>
              <TimelineTitle>Software Engineer I</TimelineTitle>
              <TimelineSubtitle>Microsoft</TimelineSubtitle>
              <TimelineDescription>
                Created a novel inference algorithm that improved accuracy from 50% to 85% while reducing execution time by 50%. Led development on an automated quality assurance platform for core inference algorithms.
              </TimelineDescription>
            </TimelineContent>
          </TimelineItem>
          
          <TimelineItem
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            viewport={{ once: true }}
          >
            <TimelineIcon>
              <FaGraduationCap />
            </TimelineIcon>
            <TimelineContent>
              <TimelineDate>May 2023</TimelineDate>
              <TimelineTitle>Master of Science in Engineering (Machine Learning)</TimelineTitle>
              <TimelineSubtitle>California Baptist University</TimelineSubtitle>
              <TimelineDescription>
                Graduated with a 4.0 GPA. Thesis: "Qualitative Algorithm for Objective Analysis of Skill Extraction in a Standardized Skill Ontology".
              </TimelineDescription>
            </TimelineContent>
          </TimelineItem>
        </TimelineContainer>
      </Section>
      
      <Section title="Skills & Expertise">
        <SkillsContainer>
          <SkillCategory>
            <CategoryTitle>
              <FaCode /> Programming Languages
            </CategoryTitle>
            <SkillsGrid>
              {['Python', 'PyTorch', 'TensorFlow', 'JavaScript', 'TypeScript', 'Java', 'C++', 'C#', 'SQL', 'R'].map((skill, index) => (
                <SkillItem
                  key={skill}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.05 }}
                  viewport={{ once: true }}
                >
                  {skill}
                </SkillItem>
              ))}
            </SkillsGrid>
          </SkillCategory>
          
          <SkillCategory>
            <CategoryTitle>
              <FaCode /> Frameworks & Libraries
            </CategoryTitle>
            <SkillsGrid>
              {['React', 'TensorFlow', 'PyTorch', 'Node.js', 'Django', 'Flask', 'Express', '.NET'].map((skill, index) => (
                <SkillItem
                  key={skill}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.05 }}
                  viewport={{ once: true }}
                >
                  {skill}
                </SkillItem>
              ))}
            </SkillsGrid>
          </SkillCategory>
          
          <SkillCategory>
            <CategoryTitle>
              <FaCode /> Tools & Technologies
            </CategoryTitle>
            <SkillsGrid>
              {['Git', 'Docker', 'Kubernetes', 'AWS', 'Azure', 'CI/CD', 'Linux', 'RESTful APIs'].map((skill, index) => (
                <SkillItem
                  key={skill}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.05 }}
                  viewport={{ once: true }}
                >
                  {skill}
                </SkillItem>
              ))}
            </SkillsGrid>
          </SkillCategory>
          
          <SkillCategory>
            <CategoryTitle>
              <FaCode /> Machine Learning & AI
            </CategoryTitle>
            <SkillsGrid>
              {['Deep Learning', 'NLP', 'Computer Vision', 'Reinforcement Learning', 'Data Mining', 'Statistical Analysis', 'Feature Engineering', 'RAG', 'Chain-of-Thought', 'Model Deployment'].map((skill, index) => (
                <SkillItem
                  key={skill}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.05 }}
                  viewport={{ once: true }}
                >
                  {skill}
                </SkillItem>
              ))}
            </SkillsGrid>
          </SkillCategory>
        </SkillsContainer>
      </Section>
      
      <Section 
        id="cta" 
        padding="4rem"
        bgColor={({ theme }) => theme.colors.backgroundDark}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          style={{ 
            textAlign: 'center',
            maxWidth: '700px',
            margin: '0 auto'
          }}
        >
          <h2 style={{ marginBottom: '1rem' }}>Want to Know More?</h2>
          <p style={{ marginBottom: '2rem', color: '#666' }}>
            Check out my resume for a detailed overview of my professional experience, education, and skills.
          </p>
          <Button to="/resume" size="large">
            View My Resume
          </Button>
        </motion.div>
      </Section>
      

    </motion.div>
  );
};

export default About;
