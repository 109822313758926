import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { motion, useScroll, useTransform, useSpring } from 'framer-motion';
import { FaArrowRight, FaLinkedin, FaGithub, FaTwitter } from 'react-icons/fa';
import robbyImage from '../assets/robby_watch.JPEG';

import Section from '../components/Section';
import Button from '../components/Button';
import Card from '../components/Card';
import HologramThree from '../components/HologramThree';

// Custom hook for parallax effects
const useParallaxEffect = (scrollY, range, output) => {
  const transform = useTransform(scrollY, range, output);
  return useSpring(transform, { stiffness: 100, damping: 30 });
};



const HeroSection = styled.section`
  height: 100vh;
  min-height: 600px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => `linear-gradient(135deg, ${theme.colors.backgroundDark}99 0%, ${theme.colors.background}99 100%)`};
`;

const HeroContent = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.text};
  z-index: 5;
  position: relative;
  
  /* Ensure text is readable over the hologram */
  h1, h2, p {
    color: ${({ theme }) => theme.colors.text};
  }
  
  /* Add a subtle backdrop to improve content visibility */
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    backdrop-filter: blur(5px);
    z-index: -1;
  }
`;

const HeroTitle = styled(motion.h1)`
  font-size: ${({ theme }) => theme.fontSizes['5xl']};
  font-weight: 700;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.fontSizes['4xl']};
  }
`;

const HeroSubtitle = styled(motion.h2)`
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  font-weight: 400;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  max-width: 600px;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.fontSizes.xl};
  }
`;

const HeroButtons = styled(motion.div)`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    width: 100%;
    max-width: 300px;
  }
`;

const SocialIcons = styled(motion.div)`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.xl};
`;

const SocialIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  background-color: rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.colors.text};
  transition: ${({ theme }) => theme.transitions.default};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    transform: translateY(-2px);
  }
`;

const AboutGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.xl};
  position: relative;
  z-index: 5;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`;

const ProfileImage = styled(motion.div)`
  width: 100%;
  height: 400px;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.lg};
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 20%; /* Move the focal point up to show more of the bottom */
  }
`;

const AboutContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AboutTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.text};
`;

const AboutText = styled.p`
  color: ${({ theme }) => theme.colors.textLight};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  line-height: 1.7;
`;

const HighlightGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacing.lg};
  position: relative;
  z-index: 5;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

const HighlightCard = styled(Card)`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.lg};
`;

const HighlightIcon = styled.div`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const HighlightTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.xl};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

const HighlightText = styled.p`
  color: ${({ theme }) => theme.colors.textLight};
`;



const Home = () => {
  const { scrollY } = useScroll();
  const heroRef = useRef(null);
  const [hologramColor] = useState('#88ccff');
  
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };
  
  // Parallax effects for profile image and about content
  const profileImageY = useParallaxEffect(scrollY, [800, 1300], [0, -30]);
  const aboutContentY = useParallaxEffect(scrollY, [800, 1300], [0, 30]);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <HeroSection ref={heroRef}>
        {/* Background Hologram */}
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
          opacity: 0.8
        }}>
          <HologramThree shape="combined" color={hologramColor} />
        </div>
        
        <HeroContent>
          <HeroTitle variants={itemVariants}>
            Robert Moseley
          </HeroTitle>
          <HeroSubtitle variants={itemVariants}>
            PhD Student | Microsoft Software Engineer | Professor of Computer Science
          </HeroSubtitle>
          <HeroButtons variants={itemVariants}>
            <Button 
              to="/about" 
              size="large"
              icon={<FaArrowRight />}
              iconPosition="right"
            >
              Learn More
            </Button>
            <Button 
              to="/contact" 
              variant="outlined" 
              size="large"
            >
              Get In Touch
            </Button>
          </HeroButtons>
          <SocialIcons variants={itemVariants}>
            <SocialIcon href="https://linkedin.com/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </SocialIcon>
            <SocialIcon href="https://github.com/" target="_blank" rel="noopener noreferrer">
              <FaGithub />
            </SocialIcon>
            <SocialIcon href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </SocialIcon>
          </SocialIcons>
          

        </HeroContent>
      </HeroSection>
      
      <Section id="about-preview" title="About Me">
        
        <AboutGrid>
          <ProfileImage
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            style={{ y: profileImageY }}
          >
            <img src={robbyImage} alt="Robert Moseley" />
          </ProfileImage>
          <AboutContent
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            style={{ y: aboutContentY }}
          >
            <AboutTitle>Who Am I?</AboutTitle>
            <AboutText>
              I am a passionate PhD student, Microsoft Software Engineer, Machine Learning Engineer, and Professor of Computer Science with a deep interest in technology and education.
            </AboutText>
            <AboutText>
              With experience spanning academia and industry, I bring a unique perspective to both software development and machine learning research. I'm dedicated to creating innovative solutions and sharing knowledge with others.
            </AboutText>
            <Button 
              to="/about" 
              icon={<FaArrowRight />}
              iconPosition="right"
              style={{ alignSelf: 'flex-start', marginTop: '1rem' }}
            >
              Read More About Me
            </Button>
          </AboutContent>
        </AboutGrid>
      </Section>
      
      <Section 
        id="highlights" 
        title="What I Do" 
        bgColor={({ theme }) => theme.colors.backgroundDark}
      >


        
        <HighlightGrid>
          <HighlightCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            viewport={{ once: true }}
          >
            <HighlightIcon>🔬</HighlightIcon>
            <HighlightTitle>Research</HighlightTitle>
            <HighlightText>
              Conducting cutting-edge research in machine learning, artificial intelligence, and computer science as a PhD student.
            </HighlightText>
          </HighlightCard>
          
          <HighlightCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.1 }}
            viewport={{ once: true }}
          >
            <HighlightIcon>💻</HighlightIcon>
            <HighlightTitle>Software Engineering</HighlightTitle>
            <HighlightText>
              Developing robust, scalable software solutions at Microsoft with a focus on performance and user experience.
            </HighlightText>
          </HighlightCard>
          
          <HighlightCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.2 }}
            viewport={{ once: true }}
          >
            <HighlightIcon>🤖</HighlightIcon>
            <HighlightTitle>Machine Learning</HighlightTitle>
            <HighlightText>
              Building and deploying machine learning models that solve real-world problems and drive business value.
            </HighlightText>
          </HighlightCard>
          
          <HighlightCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.3 }}
            viewport={{ once: true }}
          >
            <HighlightIcon>🎓</HighlightIcon>
            <HighlightTitle>Teaching</HighlightTitle>
            <HighlightText>
              Educating the next generation of computer scientists and software engineers as a professor of computer science.
            </HighlightText>
          </HighlightCard>
          
          <HighlightCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.4 }}
            viewport={{ once: true }}
          >
            <HighlightIcon>📝</HighlightIcon>
            <HighlightTitle>Technical Writing</HighlightTitle>
            <HighlightText>
              Sharing knowledge through technical articles, research papers, and educational content.
            </HighlightText>
          </HighlightCard>
          
          <HighlightCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.5 }}
            viewport={{ once: true }}
          >
            <HighlightIcon>🚀</HighlightIcon>
            <HighlightTitle>Mentorship</HighlightTitle>
            <HighlightText>
              Guiding students and early-career professionals in their journey through the tech industry.
            </HighlightText>
          </HighlightCard>
        </HighlightGrid>
      </Section>
      
      <Section id="cta" padding="4rem">
        
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          style={{ 
            textAlign: 'center',
            maxWidth: '700px',
            margin: '0 auto',
            position: 'relative',
            zIndex: 5
          }}
        >
          <h2 style={{ marginBottom: '1rem' }}>Interested in Working Together?</h2>
          <p style={{ marginBottom: '2rem', color: '#666' }}>
            Whether you're looking for a collaborator on a research project, need technical expertise, or want to discuss potential opportunities, I'd love to hear from you.
          </p>
          <Button to="/contact" size="large">
            Get In Touch
          </Button>
        </motion.div>
      </Section>
    </motion.div>
  );
};

export default Home;
