import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaDownload, FaGraduationCap, FaBriefcase, FaAward, FaCode } from 'react-icons/fa';

import Section from '../components/Section';
import Button from '../components/Button';

const PageHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  padding: 8rem 0 4rem;
  text-align: center;
`;

const PageTitle = styled.h1`
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const PageDescription = styled.p`
  max-width: 700px;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.textLight};
`;

const ResumeContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const ResumeHeader = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

const ResumeHeaderTitle = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

const ResumeHeaderSubtitle = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

const ResumeHeaderContact = styled.div`
  color: ${({ theme }) => theme.colors.textLight};
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

const ResumeSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing['2xl']};
`;

const ResumeSectionTitle = styled.h3`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  padding-bottom: ${({ theme }) => theme.spacing.sm};
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  
  svg {
    margin-right: ${({ theme }) => theme.spacing.sm};
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const ResumeItem = styled(motion.div)`
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  position: relative;
  padding-left: ${({ theme }) => theme.spacing.xl};
  
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  
  &:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 24px;
    width: 2px;
    height: calc(100% - 8px);
    background-color: ${({ theme }) => theme.colors.border};
  }
  
  &:last-child:after {
    display: none;
  }
`;

const ResumeItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const ResumeItemTitle = styled.h4`
  margin: 0;
`;

const ResumeItemDate = styled.div`
  color: ${({ theme }) => theme.colors.textLight};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: ${({ theme }) => theme.spacing.xs};
  }
`;

const ResumeItemSubtitle = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

const ResumeItemDescription = styled.p`
  color: ${({ theme }) => theme.colors.textLight};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

const ResumeItemList = styled.ul`
  color: ${({ theme }) => theme.colors.textLight};
  padding-left: ${({ theme }) => theme.spacing.lg};
  
  li {
    margin-bottom: ${({ theme }) => theme.spacing.xs};
  }
`;

const SkillsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing.md};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`;

const SkillCategory = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const SkillCategoryTitle = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.text};
`;

const SkillList = styled.ul`
  color: ${({ theme }) => theme.colors.textLight};
  padding-left: ${({ theme }) => theme.spacing.lg};
  
  li {
    margin-bottom: ${({ theme }) => theme.spacing.xs};
  }
`;

const Resume = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.4 }
    }
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <PageHeader>
        <motion.div variants={itemVariants}>
          <PageTitle>Resume</PageTitle>
          <PageDescription>
            A detailed overview of my professional experience, education, and skills
          </PageDescription>
          <Button 
            variant="primary" 
            icon={<FaDownload />} 
            style={{ marginTop: '1.5rem' }}
            href="/r_moseley_cv.pdf" // PDF generated from the R Markdown file
          >
            Download Resume
          </Button>
        </motion.div>
      </PageHeader>
      
      <Section>
        <ResumeContainer>
          <ResumeHeader>
            <ResumeHeaderTitle>Robert Moseley</ResumeHeaderTitle>
            <ResumeHeaderSubtitle>
              PhD Student | Microsoft Software Engineer II | Machine Learning Engineer | Adjunct Professor
            </ResumeHeaderSubtitle>
            <ResumeHeaderContact>
              robby.moseley@gmail.com | (661) 565-5661 | Bakersfield, CA | linkedin.com/in/robert-moseley-cs
            </ResumeHeaderContact>
          </ResumeHeader>
          
          <ResumeSection>
            <ResumeSectionTitle>
              <FaBriefcase /> Professional Experience
            </ResumeSectionTitle>
            
            <ResumeItem variants={itemVariants}>
              <ResumeItemHeader>
                <ResumeItemTitle>Software Engineer II</ResumeItemTitle>
                <ResumeItemDate>August 2024 - Present</ResumeItemDate>
              </ResumeItemHeader>
              <ResumeItemSubtitle>Microsoft, Remote / Redmond, WA</ResumeItemSubtitle>
              <ResumeItemDescription>
                Leading development on Microsoft CoPilot research projects and RAG architecture development.
              </ResumeItemDescription>
              <ResumeItemList>
                <li>Led development on a Microsoft CoPilot research project to augment LLM outputs using RAG and chain-of-thought reasoning</li>
                <li>Developed RAG architecture that reduced errors by 70% across 50 million inferences</li>
                <li>Rewrote core system to support new LLM stack of technology, improving inference accuracy from 85% to 94%</li>
              </ResumeItemList>
            </ResumeItem>
            
            <ResumeItem variants={itemVariants}>
              <ResumeItemHeader>
                <ResumeItemTitle>Software Engineer I</ResumeItemTitle>
                <ResumeItemDate>June 2023 - July 2024</ResumeItemDate>
              </ResumeItemHeader>
              <ResumeItemSubtitle>Microsoft, Remote / Redmond, WA</ResumeItemSubtitle>
              <ResumeItemDescription>
                Developed inference algorithms and data pipeline platforms for Microsoft products.
              </ResumeItemDescription>
              <ResumeItemList>
                <li>Created a novel inference algorithm that improved accuracy from 50% to 85% while reducing execution time by 50%</li>
                <li>Led development on an automated quality assurance platform for core inference algorithms</li>
                <li>Implemented a novel algorithm and infrastructure to infer relationships between entries in a 40,000-entity taxonomy</li>
              </ResumeItemList>
            </ResumeItem>
            
            <ResumeItem variants={itemVariants}>
              <ResumeItemHeader>
                <ResumeItemTitle>Software Engineering Intern</ResumeItemTitle>
                <ResumeItemDate>May 2022 - July 2022</ResumeItemDate>
              </ResumeItemHeader>
              <ResumeItemSubtitle>Microsoft, Remote / Redmond, WA</ResumeItemSubtitle>
              <ResumeItemDescription>
                Developed algorithms for skill extraction and tagging using Azure Cognitive Services and NLP.
              </ResumeItemDescription>
              <ResumeItemList>
                <li>Engineered an original algorithm using Azure Cognitive Services and SpaCy NLP to dynamically tag catalogs of data with extracted skills</li>
                <li>Developed an algorithm calculating the normalized confidence value of skill extraction</li>
                <li>Revamped the computational evaluation of word vectors in LinkedIn embeddings, resulting in a 60× improvement in code runtime</li>
              </ResumeItemList>
            </ResumeItem>
            
            <ResumeItem variants={itemVariants}>
              <ResumeItemHeader>
                <ResumeItemTitle>Adjunct Professor of Computer Science / Artificial Intelligence</ResumeItemTitle>
                <ResumeItemDate>January 2024 - Present</ResumeItemDate>
              </ResumeItemHeader>
              <ResumeItemSubtitle>California Baptist University, Riverside, CA</ResumeItemSubtitle>
              <ResumeItemDescription>
                Teaching undergraduate courses in Artificial Intelligence and Computer Science.
              </ResumeItemDescription>
              <ResumeItemList>
                <li>Taught the penultimate course on artificial intelligence for undergraduate students</li>
                <li>Covered advanced AI curriculum including projects, exams, and lectures</li>
                <li>Taught foundational and advanced AI concepts such as search algorithms, A*, shallow learning models, and deep learning</li>
              </ResumeItemList>
            </ResumeItem>
            
            <ResumeItem variants={itemVariants}>
              <ResumeItemHeader>
                <ResumeItemTitle>Adjunct Professor of Computer Science</ResumeItemTitle>
                <ResumeItemDate>August 2023 - Present</ResumeItemDate>
              </ResumeItemHeader>
              <ResumeItemSubtitle>Bakersfield College, Bakersfield, CA</ResumeItemSubtitle>
              <ResumeItemDescription>
                Teaching introductory programming courses and developing curriculum.
              </ResumeItemDescription>
              <ResumeItemList>
                <li>Instructed multiple sections of introductory programming courses, crafting curriculum from scratch</li>
                <li>Developed lectures, comprehensive assessments, and hands-on projects for students in Java, C++, and Python</li>
                <li>Introduced students to algorithms, data structures, and object-oriented programming</li>
              </ResumeItemList>
            </ResumeItem>
          </ResumeSection>
          
          <ResumeSection>
            <ResumeSectionTitle>
              <FaGraduationCap /> Education
            </ResumeSectionTitle>
            
            <ResumeItem variants={itemVariants}>
              <ResumeItemHeader>
                <ResumeItemTitle>PhD in Computer Science</ResumeItemTitle>
                <ResumeItemDate>September 2024 - Present</ResumeItemDate>
              </ResumeItemHeader>
              <ResumeItemSubtitle>University of California, Riverside</ResumeItemSubtitle>
              <ResumeItemDescription>
                Researching advanced techniques in Multimodal Machine Learning and Reinforcement Learning.
              </ResumeItemDescription>
              <ResumeItemList>
                <li>Research Focus: Multimodal Machine Learning / Reinforcement Learning</li>
                <li>Received full funding through the Department of Computer Science</li>
                <li>Advised by Dr. Salman Asif in the UCR Electrical and Computer Engineering Department</li>
              </ResumeItemList>
            </ResumeItem>
            
            <ResumeItem variants={itemVariants}>
              <ResumeItemHeader>
                <ResumeItemTitle>Master of Science in Engineering</ResumeItemTitle>
                <ResumeItemDate>May 2023</ResumeItemDate>
              </ResumeItemHeader>
              <ResumeItemSubtitle>California Baptist University, Riverside, CA</ResumeItemSubtitle>
              <ResumeItemDescription>
                Specialized in Machine Learning and Natural Language Processing.
              </ResumeItemDescription>
              <ResumeItemList>
                <li>GPA: 4.0/4.0</li>
                <li>Thesis: "Qualitative Algorithm for Objective Analysis of Skill Extraction in a Standardized Skill Ontology"</li>
              </ResumeItemList>
            </ResumeItem>
            
            <ResumeItem variants={itemVariants}>
              <ResumeItemHeader>
                <ResumeItemTitle>Bachelor of Science in Computer Science</ResumeItemTitle>
                <ResumeItemDate>May 2022</ResumeItemDate>
              </ResumeItemHeader>
              <ResumeItemSubtitle>California Baptist University, Riverside, CA</ResumeItemSubtitle>
              <ResumeItemDescription>
                Graduated with highest honors (Summa Cum Laude).
              </ResumeItemDescription>
              <ResumeItemList>
                <li>GPA: 4.0/4.0</li>
                <li>Awards: Outstanding Computer Science Student Award / College of Engineering Graduation Flag Bearer</li>
                <li>Team Captain, California Baptist University Swim & Dive Team</li>
                <li>President, Student Athlete Advisory Committee</li>
              </ResumeItemList>
            </ResumeItem>
          </ResumeSection>
          
          <ResumeSection>
            <ResumeSectionTitle>
              <FaBriefcase /> Research Experience
            </ResumeSectionTitle>
            
            <ResumeItem variants={itemVariants}>
              <ResumeItemHeader>
                <ResumeItemTitle>Software Engineer / Deep Learning Research Engineer</ResumeItemTitle>
                <ResumeItemDate>March 2021 - May 2023</ResumeItemDate>
              </ResumeItemHeader>
              <ResumeItemSubtitle>Globe Biomedical, Riverside, CA</ResumeItemSubtitle>
              <ResumeItemDescription>
                Developed CNN-based solutions for medical imaging applications.
              </ResumeItemDescription>
              <ResumeItemList>
                <li>Developed CNN-based solutions for medical imaging applications, including image segmentation of the sclera and interocular pressure detection</li>
                <li>Achieved 92% accuracy using data augmentation on a dataset of 20,000 images</li>
                <li>Designed a CNN for image quality classification achieving 96.8% accuracy</li>
                <li>Engineered a feed-forward neural network for accelerometer data, capable of real-time movement recognition at 300 inferences per second</li>
              </ResumeItemList>
            </ResumeItem>
            
            <ResumeItem variants={itemVariants}>
              <ResumeItemHeader>
                <ResumeItemTitle>Military and Civil Space Intern</ResumeItemTitle>
                <ResumeItemDate>May 2021 - August 2021</ResumeItemDate>
              </ResumeItemHeader>
              <ResumeItemSubtitle>Northrop Grumman, Azusa, CA</ResumeItemSubtitle>
              <ResumeItemDescription>
                Developed software for satellite systems and flight software.
              </ResumeItemDescription>
              <ResumeItemList>
                <li>Spearheaded the development and presentation of an interactive demo for a classified satellite for the Missile Defense Agency</li>
                <li>Authored 400 lines of Java code to automate the generation of several thousand lines of C++ flight software for a classified satellite</li>
                <li>Developed a comprehensive Python script for automated debugging and error correction of VHDL code files</li>
              </ResumeItemList>
            </ResumeItem>
          </ResumeSection>
          
          <ResumeSection>
            <ResumeSectionTitle>
              <FaAward /> Certifications & Awards
            </ResumeSectionTitle>
            
            <ResumeItem variants={itemVariants}>
              <ResumeItemHeader>
                <ResumeItemTitle>Professional Certifications</ResumeItemTitle>
              </ResumeItemHeader>
              <ResumeItemList>
                <li>Active Security Clearance: Secret (DoD, 2021-Present)</li>
              </ResumeItemList>
            </ResumeItem>
            
            <ResumeItem variants={itemVariants}>
              <ResumeItemHeader>
                <ResumeItemTitle>Academic Awards</ResumeItemTitle>
              </ResumeItemHeader>
              <ResumeItemList>
                <li>NCAA Division 1 Academic All-American (2021)</li>
                <li>NCAA Division 1 Academic All-District Honors (2020)</li>
                <li>USA Swimming Scholastic All-American (2017)</li>
                <li>Outstanding Computer Science Student Award (2022)</li>
                <li>Summa Cum Laude Graduate (2022)</li>
              </ResumeItemList>
            </ResumeItem>
          </ResumeSection>
          
          <ResumeSection>
            <ResumeSectionTitle>
              <FaCode /> Skills
            </ResumeSectionTitle>
            
            <SkillsGrid>
              <SkillCategory>
                <SkillCategoryTitle>Technical Skills</SkillCategoryTitle>
                <SkillList>
                  <li>Python (Advanced), C++ (Intermediate), Java (Intermediate), JavaScript (Intermediate)</li>
                  <li>PyTorch (Advanced), TensorFlow (Advanced), Data Engineering (Advanced)</li>
                  <li>Natural Language Processing (NLP), NLP Infrastructure, Machine Learning</li>
                  <li>Deep Neural Network Design, Convolutional Neural Networks</li>
                  <li>Retrieval-Augmented Generation (RAG), Chain-of-Thought Reasoning</li>
                  <li>Azure Cognitive Services, SpaCy NLP, Azure, Git, Docker, CI/CD, Linux</li>
                </SkillList>
              </SkillCategory>
              
              <SkillCategory>
                <SkillCategoryTitle>Leadership & Achievements</SkillCategoryTitle>
                <SkillList>
                  <li>Team Captain, California Baptist University Swim & Dive Team (3 consecutive seasons)</li>
                  <li>President, Student Athlete Advisory Committee</li>
                  <li>NCAA Constitution Development Team</li>
                  <li>Created a mental health group for athletes</li>
                  <li>Partnered with a local foster home for community service</li>
                  <li>Maintained a 4.0 cumulative GPA throughout undergraduate and graduate studies</li>
                  <li>Achieved nationally ranked times as an NCAA Division 1 swimmer</li>
                  <li>Qualified for the USA Swimming Junior National Championships</li>
                </SkillList>
              </SkillCategory>
            </SkillsGrid>
          </ResumeSection>
        </ResumeContainer>
      </Section>
      
      <Section 
        id="cta" 
        padding="4rem"
        bgColor={({ theme }) => theme.colors.backgroundDark}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          style={{ 
            textAlign: 'center',
            maxWidth: '700px',
            margin: '0 auto'
          }}
        >
          <h2 style={{ marginBottom: '1rem' }}>Interested in Working Together?</h2>
          <p style={{ marginBottom: '2rem', color: '#666' }}>
            Feel free to reach out if you'd like to discuss potential collaborations or opportunities.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', flexWrap: 'wrap' }}>
            <Button to="/contact" variant="primary">
              Contact Me
            </Button>
          </div>
        </motion.div>
      </Section>
    </motion.div>
  );
};

export default Resume;
