import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaLinkedin, FaGithub, FaGamepad } from 'react-icons/fa';
import TicTacToe from '../components/TicTacToe';

import Section from '../components/Section';
import Button from '../components/Button';

const PageHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  padding: 8rem 0 4rem;
  text-align: center;
`;

const PageTitle = styled.h1`
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const PageDescription = styled.p`
  max-width: 700px;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.textLight};
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.xl};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`;

const ContactInfo = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

const ContactInfoTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  position: relative;
  cursor: default;
`;

const ContactInfoText = styled.p`
  color: ${({ theme }) => theme.colors.textLight};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  line-height: 1.7;
`;

const ContactInfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const ContactInfoIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing.md};
  flex-shrink: 0;
`;

const ContactInfoContent = styled.div`
  h4 {
    margin: 0 0 ${({ theme }) => theme.spacing.xs};
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
  
  p {
    margin: 0;
    color: ${({ theme }) => theme.colors.textLight};
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.lg};
`;

const SocialIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  color: ${({ theme }) => theme.colors.textLight};
  transition: ${({ theme }) => theme.transitions.default};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    transform: translateY(-3px);
  }
`;

const ContactForm = styled(motion.form)`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: ${({ theme }) => theme.spacing.xl};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.md};
`;

const FormTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const FormGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.colors.text};
  font-weight: 500;
`;

const FormInput = styled.input`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.md};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-size: ${({ theme }) => theme.fontSizes.md};
  transition: ${({ theme }) => theme.transitions.default};
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
`;

const FormTextarea = styled.textarea`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.md};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-size: ${({ theme }) => theme.fontSizes.md};
  transition: ${({ theme }) => theme.transitions.default};
  min-height: 150px;
  resize: vertical;
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
`;

const FormSubmitButton = styled(Button)`
  align-self: flex-start;
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

const FormMessage = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ success, theme }) => 
    success ? theme.colors.success + '20' : theme.colors.error + '20'};
  color: ${({ success, theme }) => 
    success ? theme.colors.success : theme.colors.error};
  font-weight: 500;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 400px;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  overflow: hidden;
  margin-top: ${({ theme }) => theme.spacing.xl};
  
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const Contact = () => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  
  const [formStatus, setFormStatus] = useState({
    submitted: false,
    success: false,
    message: ''
  });
  
  const [showTicTacToe, setShowTicTacToe] = useState(false);
  const [easterEggProgress, setEasterEggProgress] = useState('');
  const [easterEggHint, setEasterEggHint] = useState(false);
  const [clickPattern, setClickPattern] = useState([]);
  const [showSecretClickSpot, setShowSecretClickSpot] = useState(false);
  
  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
    
    // Easter egg trigger - check if user types 'play' in the message field
    if (e.target.name === 'message') {
      const lastFiveChars = e.target.value.slice(-5).toLowerCase();
      setEasterEggProgress(lastFiveChars);
    }
  };
  
  useEffect(() => {
    // Check if the user has typed 'play' (case insensitive)
    if (easterEggProgress.includes('play')) {
      setShowTicTacToe(true);
      setEasterEggProgress('');
    }
  }, [easterEggProgress]);
  
  // Handle the secret click pattern
  useEffect(() => {
    // Check for the pattern: left, right, left, right
    if (clickPattern.length >= 4) {
      const lastFour = clickPattern.slice(-4);
      if (lastFour.join('') === 'leftrightrightleft') {
        setShowTicTacToe(true);
        setClickPattern([]);
      }
    }
    
    // Reset pattern after 3 seconds of inactivity
    const timer = setTimeout(() => {
      if (clickPattern.length > 0) {
        setClickPattern([]);
      }
    }, 3000);
    
    return () => clearTimeout(timer);
  }, [clickPattern]);
  
  const handleSecretClick = (direction) => {
    setClickPattern([...clickPattern, direction]);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate form
    if (!formState.name || !formState.email || !formState.message) {
      setFormStatus({
        submitted: true,
        success: false,
        message: 'Please fill in all required fields.'
      });
      return;
    }
    
    // In a real application, you would send the form data to a server here
    // For this example, we'll simulate a successful submission
    setFormStatus({
      submitted: true,
      success: true,
      message: 'Your message has been sent successfully! I will get back to you soon.'
    });
    
    // Reset form
    setFormState({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <PageHeader>
        <motion.div variants={itemVariants}>
          <PageTitle>Contact Me</PageTitle>
          <PageDescription>
            Get in touch for collaborations, questions, or just to say hello
          </PageDescription>
        </motion.div>
      </PageHeader>
      
      <Section>
        <ContactGrid>
          <ContactInfo
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
          >
            <ContactInfoTitle 
              onMouseEnter={() => setShowSecretClickSpot(true)}
              onMouseLeave={() => setShowSecretClickSpot(false)}
            >
              Let's Connect
              {showSecretClickSpot && (
                <>
                  <div 
                    onClick={() => handleSecretClick('left')} 
                    style={{ 
                      position: 'absolute', 
                      left: '-20px', 
                      top: '50%', 
                      transform: 'translateY(-50%)',
                      width: '20px', 
                      height: '100%',
                      cursor: 'pointer'
                    }} 
                  />
                  <div 
                    onClick={() => handleSecretClick('right')} 
                    style={{ 
                      position: 'absolute', 
                      right: '-20px', 
                      top: '50%', 
                      transform: 'translateY(-50%)',
                      width: '20px', 
                      height: '100%',
                      cursor: 'pointer'
                    }} 
                  />
                </>
              )}
            </ContactInfoTitle>
            <ContactInfoText>
              I'm always interested in hearing about new projects, opportunities, or just connecting with fellow professionals in the field. Feel free to reach out using any of the methods below.
            </ContactInfoText>
            
            <ContactInfoItem>
              <ContactInfoIcon>
                <FaEnvelope />
              </ContactInfoIcon>
              <ContactInfoContent>
                <h4>Email</h4>
                <p>robby.moseley@gmail.com</p>
              </ContactInfoContent>
            </ContactInfoItem>
            
            <ContactInfoItem>
              <ContactInfoIcon>
                <FaPhone />
              </ContactInfoIcon>
              <ContactInfoContent>
                <h4>Phone</h4>
                <p>(661) 565-5661</p>
              </ContactInfoContent>
            </ContactInfoItem>
            
            <ContactInfoItem>
              <ContactInfoIcon>
                <FaMapMarkerAlt />
              </ContactInfoIcon>
              <ContactInfoContent>
                <h4>Location</h4>
                <p>Riverside, CA</p>
              </ContactInfoContent>
            </ContactInfoItem>
            
            <SocialLinks>
              <SocialIcon href="https://linkedin.com/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
              </SocialIcon>
              <SocialIcon href="https://github.com/" target="_blank" rel="noopener noreferrer">
                <FaGithub />
              </SocialIcon>
            </SocialLinks>
          </ContactInfo>
          
          <ContactForm
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            onSubmit={handleSubmit}
          >
            <FormTitle>Send Me a Message</FormTitle>
            
            <FormGroup>
              <FormLabel htmlFor="name">Name *</FormLabel>
              <FormInput 
                type="text" 
                id="name" 
                name="name" 
                value={formState.name}
                onChange={handleChange}
                required
              />
            </FormGroup>
            
            <FormGroup>
              <FormLabel htmlFor="email">Email *</FormLabel>
              <FormInput 
                type="email" 
                id="email" 
                name="email" 
                value={formState.email}
                onChange={handleChange}
                required
              />
            </FormGroup>
            
            <FormGroup>
              <FormLabel htmlFor="subject">Subject</FormLabel>
              <FormInput 
                type="text" 
                id="subject" 
                name="subject" 
                value={formState.subject}
                onChange={handleChange}
              />
            </FormGroup>
            
            <FormGroup>
              <FormLabel htmlFor="message">Message *</FormLabel>
              <FormTextarea 
                id="message" 
                name="message" 
                value={formState.message}
                onChange={handleChange}
                onFocus={() => setEasterEggHint(true)}
                required
              />
              {easterEggHint && (
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: '5px',
                    fontSize: '0.8rem',
                    color: '#888',
                    marginTop: '5px'
                  }}
                >
                  <FaGamepad size={12} />
                  <span>Psst... try typing 'play' somewhere in your message</span>
                </motion.div>
              )}
            </FormGroup>
            
            <FormSubmitButton type="submit" size="large">
              Send Message
            </FormSubmitButton>
            
            {formStatus.submitted && (
              <FormMessage success={formStatus.success}>
                {formStatus.message}
              </FormMessage>
            )}
          </ContactForm>
        </ContactGrid>
        
        <MapContainer>
          {/* Replace with your actual location */}
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387193.3059353029!2d-74.25986548248684!3d40.69714941932609!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2s!4v1619528783692!5m2!1sen!2s" 
            allowFullScreen="" 
            loading="lazy"
            title="Location Map"
          />
        </MapContainer>
      </Section>
      
      <Section 
        id="faq" 
        title="Frequently Asked Questions" 
        bgColor={({ theme }) => theme.colors.backgroundDark}
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          style={{ maxWidth: '800px', margin: '0 auto' }}
        >
          {/* Sample FAQs - replace with your actual FAQs */}
          <div style={{ marginBottom: '2rem' }}>
            <h4 style={{ marginBottom: '0.5rem' }}>What services do you offer?</h4>
            <p style={{ color: '#666' }}>
              I offer a range of services including software development, machine learning consulting, technical writing, and academic mentorship. Feel free to contact me to discuss your specific needs.
            </p>
          </div>
          
          <div style={{ marginBottom: '2rem' }}>
            <h4 style={{ marginBottom: '0.5rem' }}>Are you available for freelance work?</h4>
            <p style={{ color: '#666' }}>
              Yes, I am available for select freelance projects that align with my expertise and interests. Please reach out with details about your project for consideration.
            </p>
          </div>
          
          <div style={{ marginBottom: '2rem' }}>
            <h4 style={{ marginBottom: '0.5rem' }}>Do you offer mentorship or tutoring?</h4>
            <p style={{ color: '#666' }}>
              Yes, I provide mentorship and tutoring in computer science, machine learning, and software engineering. Contact me to discuss availability and rates.
            </p>
          </div>
          
          <div>
            <h4 style={{ marginBottom: '0.5rem' }}>How quickly do you respond to inquiries?</h4>
            <p style={{ color: '#666' }}>
              I typically respond to all inquiries within 24-48 hours during weekdays. For urgent matters, please indicate so in your message.
            </p>
          </div>
        </motion.div>
      </Section>
      
      {showTicTacToe && <TicTacToe onClose={() => setShowTicTacToe(false)} />}
    </motion.div>
  );
};

export default Contact;
