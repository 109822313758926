import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaSkull, FaTimes, FaVolumeUp, FaVolumeMute } from 'react-icons/fa';

const DoomContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(5px);
`;

const GameWrapper = styled(motion.div)`
  position: relative;
  width: 90%;
  max-width: 1200px;
  height: 85vh;
  background-color: #000;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(255, 0, 0, 0.5);
`;

const GameFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const LoadingOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  z-index: 10;
`;

const LoadingText = styled(motion.div)`
  font-family: 'Courier New', monospace;
  font-size: 2rem;
  font-weight: bold;
  color: #ff0000;
  margin-top: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.7);
`;

const LoadingBar = styled(motion.div)`
  width: 60%;
  height: 20px;
  background-color: #222;
  border-radius: 10px;
  margin-top: 2rem;
  overflow: hidden;
  position: relative;
`;

const LoadingProgress = styled(motion.div)`
  height: 100%;
  background: linear-gradient(90deg, #700, #f00);
  border-radius: 10px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 20;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: rgba(255, 0, 0, 0.7);
  }
`;

const MuteButton = styled.button`
  position: absolute;
  top: 20px;
  right: 70px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 20;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: rgba(255, 0, 0, 0.7);
  }
`;

const SkullIcon = styled(motion.div)`
  margin-bottom: 1rem;
  color: #ff0000;
  font-size: 5rem;
`;

const GameTitle = styled(motion.h1)`
  font-family: 'Impact', sans-serif;
  font-size: 4rem;
  color: #ff0000;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 2rem;
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.7);
`;

const DoomGame = ({ onClose }) => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [muted, setMuted] = useState(false);
  const [gameFrameRef, setGameFrameRef] = useState(null);

  useEffect(() => {
    // Simulate loading
    const loadingInterval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(loadingInterval);
          setTimeout(() => setLoading(false), 500);
          return 100;
        }
        return prev + Math.random() * 5;
      });
    }, 100);

    return () => clearInterval(loadingInterval);
  }, []);

  const toggleMute = () => {
    setMuted(!muted);
    if (gameFrameRef) {
      try {
        // Attempt to mute the iframe content
        const frameWindow = gameFrameRef.contentWindow;
        if (frameWindow) {
          frameWindow.postMessage({ action: 'toggleMute' }, '*');
        }
      } catch (error) {
        console.log('Could not communicate with iframe');
      }
    }
  };

  return (
    <AnimatePresence mode="wait">
      {true && (
        <DoomContainer
          key="doom-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
        <GameWrapper
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ 
            type: 'spring', 
            damping: 20, 
            stiffness: 100 
          }}
        >
          <CloseButton onClick={onClose}>
            <FaTimes />
          </CloseButton>
          <MuteButton onClick={toggleMute}>
            {muted ? <FaVolumeMute /> : <FaVolumeUp />}
          </MuteButton>
          
          <GameFrame 
            src="./doom.html" 
            title="DOOM" 
            ref={ref => setGameFrameRef(ref)}
            allow="autoplay; fullscreen"
          />
          
          <AnimatePresence>
            {loading && (
              <LoadingOverlay
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <SkullIcon
                  animate={{ 
                    scale: [1, 1.1, 1],
                    rotate: [0, 5, -5, 0]
                  }}
                  transition={{ 
                    repeat: Infinity, 
                    duration: 2 
                  }}
                >
                  <FaSkull size={80} />
                </SkullIcon>
                
                <GameTitle
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  DOOM
                </GameTitle>
                
                <LoadingText
                  animate={{ 
                    opacity: [1, 0.5, 1],
                  }}
                  transition={{ 
                    repeat: Infinity, 
                    duration: 1.5 
                  }}
                >
                  Loading...
                </LoadingText>
                
                <LoadingBar>
                  <LoadingProgress
                    initial={{ width: '0%' }}
                    animate={{ width: `${progress}%` }}
                    transition={{ type: 'spring', damping: 15 }}
                  />
                </LoadingBar>
              </LoadingOverlay>
            )}
          </AnimatePresence>
        </GameWrapper>
        </DoomContainer>
      )}
    </AnimatePresence>
  );
};

export default DoomGame;
