import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { MeshDistortMaterial } from '@react-three/drei';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const HologramContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

// Scan line component (not currently used)
/* const ScanLine = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.primary}40;
  box-shadow: 0 0 10px 2px ${({ theme }) => theme.colors.primary};
  z-index: 2;
  pointer-events: none;
`; */

const HologramOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, 
    rgba(0, 0, 0, 0) 0%, 
    rgba(0, 0, 0, 0) 50%, 
    rgba(0, 0, 0, 0.5) 100%
  );
  pointer-events: none;
  z-index: 1;
  
  &:before, &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: 
      linear-gradient(0deg, transparent 24%, 
      ${({ theme }) => theme.colors.primary}08 25%, 
      ${({ theme }) => theme.colors.primary}08 26%, 
      transparent 27%, transparent 74%, 
      ${({ theme }) => theme.colors.primary}08 75%, 
      ${({ theme }) => theme.colors.primary}08 76%, 
      transparent 77%, transparent),
      linear-gradient(90deg, transparent 24%, 
      ${({ theme }) => theme.colors.primary}08 25%, 
      ${({ theme }) => theme.colors.primary}08 26%, 
      transparent 27%, transparent 74%, 
      ${({ theme }) => theme.colors.primary}08 75%, 
      ${({ theme }) => theme.colors.primary}08 76%, 
      transparent 77%, transparent);
    background-size: 50px 50px;
  }
  
  &:after {
    transform: rotate(45deg);
    background-size: 80px 80px;
  }
`;

// Glitch text component (not currently used)
/* const GlitchText = styled(motion.div)`
  position: absolute;
  top: 10px;
  left: 10px;
  color: ${({ theme }) => theme.colors.primary};
  font-family: monospace;
  font-size: 12px;
  z-index: 3;
  pointer-events: none;
  text-shadow: 0 0 5px ${({ theme }) => theme.colors.primary};
`; */



// Floating shape that moves from right to left
function FloatingShape({ color, type, startPosition, speed = 1, scale = 1, rotationSpeed = 0.5, initialRotation = [0, 0, 0] }) {
  const meshRef = useRef();
  
  // Set initial random rotation on mount
  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.rotation.x = initialRotation[0];
      meshRef.current.rotation.y = initialRotation[1];
      meshRef.current.rotation.z = initialRotation[2];
    }
  }, [initialRotation]);
  
  // Store consistent rotation speeds in a ref
  const rotationSpeeds = useRef({
    x: 0.3 + Math.random() * 0.3,
    y: 0.3 + Math.random() * 0.3,
    z: 0.3 + Math.random() * 0.3,
    sinOffset: Math.random() * Math.PI * 2, // Random offset for the sine wave
    sinSpeed: 0.3 + Math.random() * 0.4, // Random sine wave speed
    sinAmplitude: 0.2 + Math.random() * 0.4 // Random amplitude for vertical movement
  });
  
  // Store material properties in a ref
  const materialProps = useRef({
    opacity: 0.6 + Math.random() * 0.3, // Random opacity between 0.6-0.9
    emissiveIntensity: 0.5 + Math.random() * 0.5, // Random emissive intensity
    wireframe: true, // Always use wireframe
    distort: Math.random() > 0.7, // 30% chance of distortion
    distortSpeed: 0.2 + Math.random() * 0.3, // Random distortion speed
    distortStrength: 0.2 + Math.random() * 0.3, // Random distortion strength
    color: Math.random() > 0.7 ? color : `hsl(${Math.random() * 60 + 170}, 100%, 70%)` // 30% chance of random blue-cyan color
  }).current;
  
  useFrame((state, delta) => {
    if (meshRef.current) {
      // Move from right to left
      meshRef.current.position.x -= speed * delta;
      
      // Add vertical movement with smooth sine wave and random amplitude
      meshRef.current.position.y = startPosition[1] + 
        Math.sin(state.clock.elapsedTime * rotationSpeeds.current.sinSpeed + rotationSpeeds.current.sinOffset) * 
        rotationSpeeds.current.sinAmplitude;
      
      // Apply continuous rotation with varying speeds
      meshRef.current.rotation.x += rotationSpeed * delta * rotationSpeeds.current.x;
      meshRef.current.rotation.y += rotationSpeed * delta * rotationSpeeds.current.y;
      meshRef.current.rotation.z += rotationSpeed * delta * rotationSpeeds.current.z;
    }
  });
  
  // Use refs to store geometry parameters so they don't change on re-render
  const geometryParams = useRef({
    box: {
      width: 0.6 + Math.random() * 0.6, // More varied width
      height: 0.6 + Math.random() * 0.6, // More varied height
      depth: 0.6 + Math.random() * 0.6, // More varied depth
      segments: Math.floor(Math.random() * 3) + 1 // Random segments 1-3
    },
    sphere: {
      radius: 0.5,
      widthSegments: Math.max(6, Math.floor(4 + Math.random() * 12)), // More varied segments
      heightSegments: Math.max(6, Math.floor(4 + Math.random() * 12)) // More varied segments
    },
    torus: {
      radius: 0.3 + Math.random() * 0.3, // More varied radius
      tube: 0.1 + Math.random() * 0.2, // More varied tube
      radialSegments: Math.max(8, Math.floor(6 + Math.random() * 10)),
      tubularSegments: Math.max(16, Math.floor(12 + Math.random() * 12))
    },
    torusKnot: { // New shape type
      radius: 0.3 + Math.random() * 0.2,
      tube: 0.1 + Math.random() * 0.1,
      tubularSegments: Math.max(32, Math.floor(24 + Math.random() * 24)),
      radialSegments: Math.max(8, Math.floor(6 + Math.random() * 6)),
      p: Math.floor(Math.random() * 5) + 2, // Random p parameter 2-6
      q: Math.floor(Math.random() * 5) + 2  // Random q parameter 2-6
    },
    octahedron: { // New shape type
      radius: 0.5 + Math.random() * 0.3,
      detail: Math.floor(Math.random() * 2) // Detail level 0-1
    }
  }).current;
  
  // Render the appropriate shape based on type with consistent variations
  const renderGeometry = () => {
    switch (type) {
      case 'box':
        return (
          <boxGeometry 
            args={[
              geometryParams.box.width, 
              geometryParams.box.height, 
              geometryParams.box.depth,
              geometryParams.box.segments,
              geometryParams.box.segments,
              geometryParams.box.segments
            ]} 
          />
        );
      case 'sphere':
        return (
          <sphereGeometry 
            args={[
              geometryParams.sphere.radius, 
              geometryParams.sphere.widthSegments, 
              geometryParams.sphere.heightSegments
            ]} 
          />
        );
      case 'torus':
        return (
          <torusGeometry 
            args={[
              geometryParams.torus.radius, 
              geometryParams.torus.tube, 
              geometryParams.torus.radialSegments, 
              geometryParams.torus.tubularSegments
            ]} 
          />
        );
      case 'torusKnot':
        return (
          <torusKnotGeometry 
            args={[
              geometryParams.torusKnot.radius,
              geometryParams.torusKnot.tube,
              geometryParams.torusKnot.tubularSegments,
              geometryParams.torusKnot.radialSegments,
              geometryParams.torusKnot.p,
              geometryParams.torusKnot.q
            ]}
          />
        );
      case 'octahedron':
        return (
          <octahedronGeometry 
            args={[
              geometryParams.octahedron.radius,
              geometryParams.octahedron.detail
            ]}
          />
        );
      default:
        return <sphereGeometry args={[0.5, 16, 16]} />;
    }
  };
  
  // Render the mesh with the appropriate material
  if (materialProps.distort) {
    return (
      <mesh ref={meshRef} position={startPosition} scale={scale}>
        {renderGeometry()}
        <MeshDistortMaterial
          color={materialProps.color}
          transparent
          opacity={materialProps.opacity}
          emissive={materialProps.color}
          emissiveIntensity={materialProps.emissiveIntensity}
          wireframe={materialProps.wireframe}
          speed={materialProps.distortSpeed}
          distort={materialProps.distortStrength}
        />
      </mesh>
    );
  } else {
    return (
      <mesh ref={meshRef} position={startPosition} scale={scale}>
        {renderGeometry()}
        <meshStandardMaterial 
          color={materialProps.color}
          transparent
          opacity={materialProps.opacity}
          emissive={materialProps.color}
          emissiveIntensity={materialProps.emissiveIntensity}
          wireframe={materialProps.wireframe}
        />
      </mesh>
    );
  }
}

// Scene with continuously spawning objects
function HologramScene({ color }) {
  // Initialize with a few shapes already visible - starting from the right edge
  const initialShapes = [
    {
      id: 0,
      type: 'box',
      position: [20, 2, -2], // Start from right edge
      scale: 0.5,
      speed: 1.5,
      rotationSpeed: 0.5, // Much faster rotation
      initialRotation: [Math.PI/4, Math.PI/3, 0]
    },
    {
      id: 1,
      type: 'sphere',
      position: [22, -3, -1], // Start from right edge
      scale: 0.7,
      speed: 1.2,
      rotationSpeed: 0.5, // Much faster rotation
      initialRotation: [0, Math.PI/4, Math.PI/6]
    },
    {
      id: 2,
      type: 'torus',
      position: [24, 0, -3], // Start from right edge
      scale: 0.6,
      speed: 1.8,
      rotationSpeed: 0.5, // Much faster rotation
      initialRotation: [Math.PI/6, 0, Math.PI/3]
    },
    {
      id: 3,
      type: 'box',
      position: [26, 4, -4], // Start from right edge
      scale: 0.3,
      speed: 1.3,
      rotationSpeed: 0.5, // Much faster rotation
      initialRotation: [Math.PI/5, Math.PI/5, Math.PI/5]
    },
    {
      id: 4,
      type: 'sphere',
      position: [28, -2, -2], // Start from right edge
      scale: 0.9,
      speed: 0.8,
      rotationSpeed: 0.5, // Much faster rotation
      initialRotation: [Math.PI/2, Math.PI/4, 0]
    }
  ];
  
  const [shapes, setShapes] = useState(initialShapes);
  const maxShapes = 30; // Much higher limit to prevent premature deletion
  const shapeTypes = ['box', 'sphere', 'torus', 'torusKnot', 'octahedron'];
  const nextShapeId = useRef(3); // Start after our initial shapes
  

  
  // Direct spawning in useFrame for guaranteed continuous spawning
  const lastSpawnTime = useRef(0);
  const nextSpawnDelay = useRef(Math.random() * 2000 + 1000); // Random delay between 1-3 seconds
  
  // Function to create a new shape
  const createShape = () => {
    const yPos = (Math.random() - 0.5) * 10; // Random y position between -5 and 5
    const zPos = -1 - Math.random() * 5; // z position between -1 and -6
    
    // More varied sizes - some very small, some larger
    const scale = Math.random() < 0.3 
      ? 0.2 + Math.random() * 0.3 // 30% chance of small shape (0.2-0.5)
      : 0.5 + Math.random() * 0.8; // 70% chance of larger shape (0.5-1.3)
    
    const speed = 1 + Math.random() * 2; // Random speed between 1 and 3
    const rotationSpeed = 0.5; // Fixed faster rotation speed
    
    // Random initial rotation
    const initialRotation = [
      Math.random() * Math.PI * 2, // 0 to 2π
      Math.random() * Math.PI * 2,
      Math.random() * Math.PI * 2
    ];
    
    return {
      id: nextShapeId.current++,
      type: shapeTypes[Math.floor(Math.random() * shapeTypes.length)],
      position: [25, yPos, zPos], // Start from far right edge of screen
      scale,
      speed,
      rotationSpeed,
      initialRotation
    };
  };
  
  // Use useFrame for both movement and spawning to ensure it runs continuously
  useFrame((state) => {
    // Only remove shapes that have moved completely off-screen (far left)
    setShapes(prevShapes => {
      // Only filter if we need to (when there are shapes that have moved far off-screen)
      const hasOffscreenShapes = prevShapes.some(shape => shape.position[0] <= -40);
      if (hasOffscreenShapes) {
        // Filter out far off-screen shapes
        return prevShapes.filter(shape => shape.position[0] > -40);
      }
      return prevShapes;
    });
    
    // Check if it's time to spawn a new shape
    const currentTime = state.clock.elapsedTime * 1000; // Convert to milliseconds
    
    if (currentTime - lastSpawnTime.current > nextSpawnDelay.current) {
      // Time to spawn a new shape
      setShapes(prevShapes => {
        // Add new shape
        const newShape = createShape();
        
        // If we're at or over the limit, only remove shapes that are off-screen
        if (prevShapes.length >= maxShapes) {
          // First check if any shapes are already off-screen (left of viewport)
          const offscreenShapes = prevShapes.filter(shape => shape.position[0] < -20);
          
          if (offscreenShapes.length > 0) {
            // If we have off-screen shapes, find the furthest left one
            let oldestIndex = 0;
            let furthestLeft = Infinity;
            
            prevShapes.forEach((shape, index) => {
              if (shape.position[0] < -20 && shape.position[0] < furthestLeft) {
                furthestLeft = shape.position[0];
                oldestIndex = index;
              }
            });
            
            // Return all shapes except the furthest left off-screen one, plus the new one
            return [
              ...prevShapes.slice(0, oldestIndex),
              ...prevShapes.slice(oldestIndex + 1),
              newShape
            ];
          } else {
            // If no shapes are off-screen yet, find the one closest to leaving the screen
            let oldestIndex = 0;
            let furthestLeft = Infinity;
            
            prevShapes.forEach((shape, index) => {
              if (shape.position[0] < furthestLeft) {
                furthestLeft = shape.position[0];
                oldestIndex = index;
              }
            });
            
            // Return all shapes except the oldest, plus the new one
            return [
              ...prevShapes.slice(0, oldestIndex),
              ...prevShapes.slice(oldestIndex + 1),
              newShape
            ];
          }
        }
        
        // Otherwise just add the new shape
        return [...prevShapes, newShape];
      });
      
      // Update last spawn time
      lastSpawnTime.current = currentTime;
      
      // Set next random spawn delay between 1-3 seconds
      nextSpawnDelay.current = Math.random() * 2000 + 1000;
    }
  });
  
  return (
    <>
      <ambientLight intensity={0.2} />
      <pointLight position={[10, 5, 5]} intensity={1.0} color={color || "#88ccff"} />
      <pointLight position={[-10, -5, -5]} intensity={0.7} color={color || "#88ccff"} />
      <pointLight position={[0, 0, 10]} intensity={0.5} color={color || "#88ccff"} />
      
      {/* Render all active shapes */}
      {shapes.map(shape => (
        <FloatingShape 
          key={shape.id}
          color={color}
          type={shape.type}
          startPosition={shape.position}
          scale={shape.scale}
          speed={shape.speed}
          rotationSpeed={shape.rotationSpeed}
          initialRotation={shape.initialRotation || [0, 0, 0]}
        />
      ))}
    </>
  );
}

// Removed random text generator

const HologramThree = ({ color = "#88ccff" }) => {
  const containerRef = useRef(null);
  // Scan line position state (not currently used)
  const [, setScanLinePosition] = useState(0);
  
  useEffect(() => {
    // Scan line animation
    const scanLineInterval = setInterval(() => {
      setScanLinePosition(prev => {
        if (containerRef.current) {
          const height = containerRef.current.clientHeight;
          return (prev + 2) % height;
        }
        return prev;
      });
    }, 30);
    
    return () => {
      clearInterval(scanLineInterval);
    };
  }, []);
  
  return (
    <HologramContainer 
      ref={containerRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Canvas camera={{ position: [0, 0, 8], fov: 65 }}>
        <HologramScene color={color} />
      </Canvas>
      
      <HologramOverlay 
        animate={{ 
          opacity: [0.5, 0.7, 0.5],
        }} 
        transition={{ 
          repeat: Infinity,
          duration: 5
        }}
      />
      
      {/* Scan line removed as requested */}
    </HologramContainer>
  );
};

export default HologramThree;
