import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const ButtonStyles = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme, size }) => 
    size === 'small' 
      ? `${theme.spacing.sm} ${theme.spacing.lg}` 
      : size === 'large' 
        ? `${theme.spacing.md} ${theme.spacing['2xl']}` 
        : `${theme.spacing.md} ${theme.spacing.xl}`};
  font-size: ${({ theme, size }) => 
    size === 'small' 
      ? theme.fontSizes.sm 
      : size === 'large' 
        ? theme.fontSizes.lg 
        : theme.fontSizes.md};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 600;
  letter-spacing: 0.02em;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  transition: ${({ theme }) => theme.transitions.bounce};
  cursor: pointer;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  
  ${({ variant, theme }) => {
    switch (variant) {
      case 'outlined':
        return css`
          background-color: transparent;
          color: ${theme.colors.text};
          border: 1px solid ${theme.colors.text};
          
          &:hover {
            background-color: transparent;
            color: ${theme.colors.accent};
            border-color: ${theme.colors.accent};
            transform: translateY(-2px);
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.03);
          }
          
          &:active {
            transform: translateY(-1px);
          }
        `;
      case 'text':
        return css`
          background-color: transparent;
          color: ${theme.colors.text};
          border: none;
          padding: ${({ theme, size }) => 
            size === 'small' 
              ? `${theme.spacing.xs} ${theme.spacing.sm}` 
              : size === 'large' 
                ? `${theme.spacing.md} ${theme.spacing.md}` 
                : `${theme.spacing.sm} ${theme.spacing.sm}`};
          
          &:hover {
            color: ${theme.colors.accent};
            transform: translateY(-1px);
          }
          
          &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 1px;
            bottom: 0;
            left: 0;
            background-color: ${theme.colors.accent};
            transition: ${theme.transitions.default};
          }
          
          &:hover:after {
            width: 100%;
          }
        `;
      case 'secondary':
        return css`
          background-color: ${theme.colors.secondary};
          color: ${theme.colors.background};
          border: none;
          
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: ${theme.colors.primary};
            z-index: -1;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform ${theme.transitions.default};
          }
          
          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.03);
          }
          
          &:hover:before {
            transform: scaleX(1);
            transform-origin: right;
          }
          
          &:active {
            transform: translateY(-1px);
          }
        `;
      default: // primary
        return css`
          background-color: ${theme.colors.primary};
          color: ${theme.colors.background};
          border: none;
          
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: ${theme.colors.accent};
            z-index: -1;
            transform: scaleX(0);
            transform-origin: right;
            transition: transform ${theme.transitions.default};
          }
          
          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.03);
          }
          
          &:hover:before {
            transform: scaleX(1);
            transform-origin: left;
          }
          
          &:active {
            transform: translateY(-1px);
          }
        `;
    }
  }}
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    
    &:hover {
      box-shadow: none;
      background-color: ${({ variant, theme }) => 
        variant === 'primary' 
          ? theme.colors.primary 
          : variant === 'secondary' 
            ? theme.colors.secondary 
            : 'transparent'};
      color: ${({ variant, theme }) => 
        variant === 'outlined' || variant === 'text' 
          ? theme.colors.primary 
          : 'white'};
    }
  }
  
  ${({ fullWidth }) => fullWidth && css`
    width: 100%;
  `}
  
  svg {
    margin-right: ${({ theme, iconPosition }) => 
      iconPosition === 'right' ? 0 : theme.spacing.sm};
    margin-left: ${({ theme, iconPosition }) => 
      iconPosition === 'right' ? theme.spacing.sm : 0};
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;

const StyledButton = styled.button`
  ${ButtonStyles}
`;

const StyledLink = styled(Link)`
  ${ButtonStyles}
`;

const StyledAnchor = styled.a`
  ${ButtonStyles}
`;

const Button = ({ 
  children, 
  variant = 'primary', 
  size = 'medium', 
  fullWidth = false,
  disabled = false,
  to,
  href,
  icon,
  iconPosition = 'left',
  onClick,
  type = 'button',
  ...props 
}) => {
  const buttonProps = {
    variant,
    size,
    fullWidth,
    disabled,
    iconPosition,
    ...props
  };
  
  // If icon is provided, add it to children based on position
  const content = icon ? (
    <>
      {iconPosition === 'left' && icon}
      {children}
      {iconPosition === 'right' && icon}
    </>
  ) : children;
  
  // Render as Link if 'to' prop is provided
  if (to) {
    return (
      <StyledLink to={to} {...buttonProps}>
        {content}
      </StyledLink>
    );
  }
  
  // Render as anchor if 'href' prop is provided
  if (href) {
    return (
      <StyledAnchor 
        href={href} 
        target="_blank" 
        rel="noopener noreferrer" 
        {...buttonProps}
      >
        {content}
      </StyledAnchor>
    );
  }
  
  // Otherwise render as button
  return (
    <StyledButton 
      onClick={onClick} 
      disabled={disabled} 
      type={type}
      {...buttonProps}
    >
      {content}
    </StyledButton>
  );
};

export default Button;
