import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBars, FaTimes, FaSkull } from 'react-icons/fa';
import DoomGame from './DoomGame';

const NavbarContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: ${({ scrolled, theme }) => 
    scrolled ? 'rgba(255, 255, 255, 0.9)' : 'transparent'};
  backdrop-filter: ${({ scrolled }) => 
    scrolled ? 'blur(15px)' : 'none'};
  box-shadow: ${({ scrolled, theme }) => 
    scrolled ? '0 5px 20px rgba(0, 0, 0, 0.02)' : 'none'};
  transition: ${({ theme }) => theme.transitions.default};
  padding: ${({ scrolled, theme }) => 
    scrolled ? `${theme.spacing.md} 0` : `${theme.spacing.lg} 0`};
  border-bottom: ${({ scrolled, theme }) => 
    scrolled ? `1px solid ${theme.colors.border}` : 'none'};
`;

const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.md};
`;

const Logo = styled(Link)`
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 700;
  color: ${({ theme, scrolled, isHomePage }) => 
    scrolled || !isHomePage ? theme.colors.text : theme.colors.background};
  text-decoration: none;
  z-index: 2;
  letter-spacing: -0.02em;
  transition: ${({ theme }) => theme.transitions.default};
  position: relative;
  display: flex;
  align-items: center;
  
  &:hover {
    transform: translateY(-1px);
    color: ${({ theme, scrolled, isHomePage }) => 
    scrolled || !isHomePage ? theme.colors.primary : theme.colors.backgroundDark};
  }
`;

const LogoInitials = styled.span`
  display: inline-block;
  color: ${({ theme, isHomePage, scrolled }) => 
    scrolled || !isHomePage ? theme.colors.primary : theme.colors.background};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  letter-spacing: -0.02em;
  transition: ${({ theme }) => theme.transitions.default};
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
    box-shadow: -10px 0 30px rgba(0, 0, 0, 0.05);
    transform: ${({ isOpen }) => 
      isOpen ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
    z-index: 1;
  }
`;

const NavLink = styled(Link)`
  margin: 0 ${({ theme }) => theme.spacing.lg};
  color: ${({ theme, scrolled, isActive, isHomePage }) => 
    isActive 
      ? theme.colors.primary 
      : scrolled || !isHomePage
        ? theme.colors.text 
        : theme.colors.background};
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.primary};
  letter-spacing: 0.02em;
  position: relative;
  padding: ${({ theme }) => theme.spacing.xs} 0;
  transition: ${({ theme }) => theme.transitions.default};
  
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${({ isActive }) => isActive ? '100%' : '0'};
    height: 2px;
    background-color: ${({ theme, scrolled, isHomePage }) => 
      scrolled || !isHomePage ? theme.colors.primary : theme.colors.background};
    transition: ${({ theme }) => theme.transitions.elastic};
    transform-origin: left;
  }
  
  &:hover {
    transform: translateY(-2px);
  }
  
  &:hover:after {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: ${({ theme }) => theme.spacing.md} 0;
    color: ${({ theme, isActive }) => 
      isActive ? theme.colors.primary : theme.colors.text};
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: ${({ theme }) => theme.fontSizes.xl};
  color: ${({ theme, scrolled, isHomePage }) => 
    scrolled || !isHomePage ? theme.colors.text : theme.colors.background};
  cursor: pointer;
  z-index: 2;
  transition: ${({ theme }) => theme.transitions.bounce};
  padding: ${({ theme }) => theme.spacing.xs};
  
  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
  }
`;

const SecretIcon = styled(motion.div)`
  position: absolute;
  top: -10px;
  right: -30px;
  color: ${({ theme, scrolled }) => 
    scrolled ? theme.colors.text : theme.colors.background};
  opacity: 0;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;
  
  &:hover {
    color: #ff0000;
    transform: scale(1.2);
  }
`;

const KonamiCodeSequence = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [showDoom, setShowDoom] = useState(false);
  const [showSecretIcon, setShowSecretIcon] = useState(false);
  const [konamiProgress, setKonamiProgress] = useState([]);
  const [clickCount, setClickCount] = useState(0);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // Close mobile menu when route changes
    setIsOpen(false);
  }, [location]);
  
  // Konami code detection
  useEffect(() => {
    const handleKeyDown = (e) => {
      const newProgress = [...konamiProgress, e.key];
      
      // Keep only the last N keys pressed, where N is the length of the Konami code
      if (newProgress.length > KonamiCodeSequence.length) {
        newProgress.shift();
      }
      
      setKonamiProgress(newProgress);
      
      // Check if the Konami code has been entered
      if (newProgress.length === KonamiCodeSequence.length) {
        const isKonamiCode = newProgress.every((key, index) => key === KonamiCodeSequence[index]);
        if (isKonamiCode) {
          setShowDoom(true);
          setKonamiProgress([]);
        }
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [konamiProgress]);
  
  // Secret logo click counter
  useEffect(() => {
    if (clickCount >= 3) {
      setShowSecretIcon(true);
      
      // Hide the icon after 5 seconds if not clicked
      const timer = setTimeout(() => {
        setShowSecretIcon(false);
        setClickCount(0);
      }, 5000);
      
      return () => clearTimeout(timer);
    }
  }, [clickCount]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <NavbarContainer scrolled={scrolled}>
        <NavContent>
          <Logo 
            to="/" 
            scrolled={scrolled}
            isHomePage={isHomePage}
            onClick={() => setClickCount(prev => prev + 1)}
          >
             <LogoInitials isHomePage={isHomePage} scrolled={scrolled}>robbymoseley.com</LogoInitials>
             <AnimatePresence>
               {showSecretIcon && (
                 <SecretIcon
                   initial={{ opacity: 0, scale: 0.5 }}
                   animate={{ opacity: 1, scale: 1 }}
                   exit={{ opacity: 0, scale: 0.5 }}
                   transition={{ type: 'spring', damping: 15 }}
                   onClick={(e) => {
                     e.preventDefault();
                     e.stopPropagation();
                     setShowDoom(true);
                     setShowSecretIcon(false);
                     setClickCount(0);
                   }}
                   scrolled={scrolled}
                 >
                   <FaSkull size={18} />
                 </SecretIcon>
               )}
             </AnimatePresence>
          </Logo>
          
          <MobileMenuButton onClick={toggleMenu} scrolled={scrolled} isHomePage={isHomePage}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </MobileMenuButton>
          
          <Overlay 
            isOpen={isOpen}
            initial={{ opacity: 0 }}
            animate={{ opacity: isOpen ? 1 : 0 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsOpen(false)}
          />
          
          <NavLinks isOpen={isOpen}>
            <NavLink 
              to="/" 
              scrolled={scrolled} 
              isHomePage={isHomePage}
              isActive={location.pathname === '/'}
            >
              Home
            </NavLink>
            <NavLink 
              to="/about" 
              scrolled={scrolled} 
              isHomePage={isHomePage}
              isActive={location.pathname === '/about'}
            >
              About
            </NavLink>
            <NavLink 
              to="/resume" 
              scrolled={scrolled} 
              isHomePage={isHomePage}
              isActive={location.pathname === '/resume'}
            >
              Resume
            </NavLink>

            <NavLink 
              to="/contact" 
              scrolled={scrolled} 
              isHomePage={isHomePage}
              isActive={location.pathname === '/contact'}
            >
              Contact
            </NavLink>
          </NavLinks>
        </NavContent>
      </NavbarContainer>
      
      {showDoom && <DoomGame onClose={() => setShowDoom(false)} />}
    </>
  );
};

export default Navbar;
