import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaGithub, FaExternalLinkAlt, FaFilter } from 'react-icons/fa';

import Section from '../components/Section';
import Button from '../components/Button';
import Card from '../components/Card';

// Placeholder project images - replace with your actual project images
const PROJECT_IMAGES = [
  'https://images.unsplash.com/photo-1555066931-4365d14bab8c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
  'https://images.unsplash.com/photo-1551033406-611cf9a28f67?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80',
  'https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
  'https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1472&q=80',
  'https://images.unsplash.com/photo-1581276879432-15e50529f34b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
  'https://images.unsplash.com/photo-1587620962725-abab7fe55159?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1631&q=80'
];

const PageHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  padding: 8rem 0 4rem;
  text-align: center;
`;

const PageTitle = styled.h1`
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const PageDescription = styled.p`
  max-width: 700px;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.textLight};
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

const FilterButton = styled.button`
  background-color: ${({ active, theme }) => 
    active ? theme.colors.primary : theme.colors.backgroundDark};
  color: ${({ active, theme }) => 
    active ? 'white' : theme.colors.text};
  border: none;
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: 500;
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  
  &:hover {
    background-color: ${({ active, theme }) => 
      active ? theme.colors.primary : '#e0e0e0'};
  }
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: ${({ theme }) => theme.spacing.xl};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

const ProjectCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ProjectImage = styled.div`
  width: 100%;
  height: 200px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.lg};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.lg};
`;

const ProjectContent = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ProjectTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

const ProjectCategories = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.xs};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const ProjectCategory = styled.span`
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  color: ${({ theme }) => theme.colors.textLight};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;

const ProjectDescription = styled.p`
  color: ${({ theme }) => theme.colors.textLight};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  flex: 1;
`;

const ProjectLinks = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: auto;
`;

const Projects = () => {
  // Sample project data - replace with your actual projects
  const projectsData = [
    {
      id: 1,
      title: 'Machine Learning Research Project',
      categories: ['Machine Learning', 'Research', 'Python'],
      description: 'A novel approach to solving [specific problem] using deep learning techniques. Achieved state-of-the-art results on [benchmark dataset].',
      image: PROJECT_IMAGES[0],
      github: 'https://github.com/',
      demo: 'https://demo-link.com/'
    },
    {
      id: 2,
      title: 'Natural Language Processing Tool',
      categories: ['NLP', 'Python', 'TensorFlow'],
      description: 'Developed a tool for analyzing and processing natural language text. Features include sentiment analysis, entity recognition, and text summarization.',
      image: PROJECT_IMAGES[1],
      github: 'https://github.com/',
      demo: null
    },
    {
      id: 3,
      title: 'Web Application for Data Visualization',
      categories: ['Web Development', 'JavaScript', 'D3.js'],
      description: 'Interactive web application for visualizing complex datasets. Allows users to upload their own data and create custom visualizations.',
      image: PROJECT_IMAGES[2],
      github: 'https://github.com/',
      demo: 'https://demo-link.com/'
    },
    {
      id: 4,
      title: 'Mobile App for Health Tracking',
      categories: ['Mobile Development', 'React Native', 'Health'],
      description: 'A cross-platform mobile application for tracking health metrics and providing personalized recommendations based on user data.',
      image: PROJECT_IMAGES[3],
      github: 'https://github.com/',
      demo: 'https://demo-link.com/'
    },
    {
      id: 5,
      title: 'Computer Vision System',
      categories: ['Computer Vision', 'Python', 'PyTorch'],
      description: 'Developed a computer vision system for [specific application]. Implemented object detection, tracking, and recognition algorithms.',
      image: PROJECT_IMAGES[4],
      github: 'https://github.com/',
      demo: null
    },
    {
      id: 6,
      title: 'Full-Stack Web Platform',
      categories: ['Web Development', 'React', 'Node.js', 'MongoDB'],
      description: 'A comprehensive web platform for [specific purpose]. Features include user authentication, data management, and real-time updates.',
      image: PROJECT_IMAGES[5],
      github: 'https://github.com/',
      demo: 'https://demo-link.com/'
    }
  ];

  // Extract all unique categories
  const allCategories = ['All', ...new Set(projectsData.flatMap(project => project.categories))];
  
  const [activeFilter, setActiveFilter] = useState('All');
  
  // Filter projects based on active filter
  const filteredProjects = activeFilter === 'All' 
    ? projectsData 
    : projectsData.filter(project => project.categories.includes(activeFilter));

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.4 }
    }
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <PageHeader>
        <motion.div variants={itemVariants}>
          <PageTitle>Projects</PageTitle>
          <PageDescription>
            Explore a selection of my recent work and research projects
          </PageDescription>
        </motion.div>
      </PageHeader>
      
      <Section>
        <FilterContainer>
          <FaFilter style={{ marginRight: '0.5rem', color: '#666' }} />
          {allCategories.map(category => (
            <FilterButton
              key={category}
              active={activeFilter === category}
              onClick={() => setActiveFilter(category)}
            >
              {category}
            </FilterButton>
          ))}
        </FilterContainer>
        
        <ProjectsGrid>
          {filteredProjects.map(project => (
            <ProjectCard
              key={project.id}
              as={motion.div}
              variants={itemVariants}
              whileHover={{ y: -10 }}
              transition={{ duration: 0.3 }}
            >
              <ProjectImage src={project.image} />
              <ProjectContent>
                <ProjectTitle>{project.title}</ProjectTitle>
                <ProjectCategories>
                  {project.categories.map(category => (
                    <ProjectCategory key={category}>
                      {category}
                    </ProjectCategory>
                  ))}
                </ProjectCategories>
                <ProjectDescription>
                  {project.description}
                </ProjectDescription>
                <ProjectLinks>
                  {project.github && (
                    <Button 
                      href={project.github}
                      variant="outlined"
                      size="small"
                      icon={<FaGithub />}
                    >
                      Code
                    </Button>
                  )}
                  {project.demo && (
                    <Button 
                      href={project.demo}
                      size="small"
                      icon={<FaExternalLinkAlt />}
                    >
                      Live Demo
                    </Button>
                  )}
                </ProjectLinks>
              </ProjectContent>
            </ProjectCard>
          ))}
        </ProjectsGrid>
      </Section>
      
      <Section 
        title="Research Publications" 
        bgColor={({ theme }) => theme.colors.backgroundDark}
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <ProjectsGrid>
            {/* Sample research publications - replace with your actual publications */}
            <ProjectCard>
              <ProjectContent>
                <ProjectTitle>"Title of Research Paper 1"</ProjectTitle>
                <p style={{ color: '#666', marginBottom: '0.5rem' }}>
                  <em>Conference/Journal Name, 2023</em>
                </p>
                <ProjectCategories>
                  <ProjectCategory>Machine Learning</ProjectCategory>
                  <ProjectCategory>Research</ProjectCategory>
                </ProjectCategories>
                <ProjectDescription>
                  Abstract: Brief description of the research paper and its contributions to the field.
                </ProjectDescription>
                <ProjectLinks>
                  <Button 
                    href="https://paper-link.com/"
                    size="small"
                    icon={<FaExternalLinkAlt />}
                  >
                    Read Paper
                  </Button>
                </ProjectLinks>
              </ProjectContent>
            </ProjectCard>
            
            <ProjectCard>
              <ProjectContent>
                <ProjectTitle>"Title of Research Paper 2"</ProjectTitle>
                <p style={{ color: '#666', marginBottom: '0.5rem' }}>
                  <em>Conference/Journal Name, 2022</em>
                </p>
                <ProjectCategories>
                  <ProjectCategory>Computer Vision</ProjectCategory>
                  <ProjectCategory>Deep Learning</ProjectCategory>
                </ProjectCategories>
                <ProjectDescription>
                  Abstract: Brief description of the research paper and its contributions to the field.
                </ProjectDescription>
                <ProjectLinks>
                  <Button 
                    href="https://paper-link.com/"
                    size="small"
                    icon={<FaExternalLinkAlt />}
                  >
                    Read Paper
                  </Button>
                </ProjectLinks>
              </ProjectContent>
            </ProjectCard>
            
            <ProjectCard>
              <ProjectContent>
                <ProjectTitle>"Title of Research Paper 3"</ProjectTitle>
                <p style={{ color: '#666', marginBottom: '0.5rem' }}>
                  <em>Conference/Journal Name, 2021</em>
                </p>
                <ProjectCategories>
                  <ProjectCategory>Natural Language Processing</ProjectCategory>
                  <ProjectCategory>Transformers</ProjectCategory>
                </ProjectCategories>
                <ProjectDescription>
                  Abstract: Brief description of the research paper and its contributions to the field.
                </ProjectDescription>
                <ProjectLinks>
                  <Button 
                    href="https://paper-link.com/"
                    size="small"
                    icon={<FaExternalLinkAlt />}
                  >
                    Read Paper
                  </Button>
                </ProjectLinks>
              </ProjectContent>
            </ProjectCard>
          </ProjectsGrid>
        </motion.div>
      </Section>
      
      <Section id="cta" padding="4rem">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          style={{ 
            textAlign: 'center',
            maxWidth: '700px',
            margin: '0 auto'
          }}
        >
          <h2 style={{ marginBottom: '1rem' }}>Have a Project in Mind?</h2>
          <p style={{ marginBottom: '2rem', color: '#666' }}>
            I'm always open to discussing new projects, creative ideas, or opportunities to be part of your vision.
          </p>
          <Button to="/contact" size="large">
            Let's Talk
          </Button>
        </motion.div>
      </Section>
    </motion.div>
  );
};

export default Projects;
