import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const SectionContainer = styled(motion.section)`
  padding: ${({ theme, padding }) => padding || theme.spacing['4xl']} 0;
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.background};
  position: relative;
  overflow: hidden;
  margin: ${({ theme }) => theme.spacing.xl} 0;
`;

const SectionInner = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.xl};
  position: relative;
  z-index: 1;
`;

const SectionTitle = styled(motion.h2)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing['3xl']};
  position: relative;
  color: ${({ theme, color }) => color || theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes['3xl']};
  font-weight: 800;
  letter-spacing: -0.02em;
  
  &:after {
    content: '';
    display: block;
    width: 40px;
    height: 2px;
    background-color: ${({ theme, underlineColor }) => underlineColor || theme.colors.primary};
    margin: 1rem auto 0;
    opacity: 0.7;
  }
`;

const Section = ({ 
  children, 
  title, 
  id, 
  bgColor, 
  padding,
  titleColor,
  underlineColor,
  ...props 
}) => {
  const containerVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.8,
        ease: [0.25, 0.1, 0.25, 1],
        when: "beforeChildren",
        staggerChildren: 0.3
      }
    }
  };

  const titleVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.6,
        ease: [0.25, 0.1, 0.25, 1]
      }
    }
  };

  return (
    <SectionContainer 
      id={id}
      bgColor={bgColor}
      padding={padding}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.1 }}
      variants={containerVariants}
      {...props}
    >
      <SectionInner>
        {title && (
          <SectionTitle 
            variants={titleVariants}
            color={titleColor}
            underlineColor={underlineColor}
          >
            {title}
          </SectionTitle>
        )}
        {children}
      </SectionInner>
    </SectionContainer>
  );
};

export default Section;
