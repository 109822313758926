import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const CardContainer = styled(motion.div)`
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.background};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme, elevation }) => 
    elevation === 'high' 
      ? theme.shadows.xl 
      : elevation === 'low' 
        ? theme.shadows.sm 
        : theme.shadows.md};
  overflow: hidden;
  transition: ${({ theme }) => theme.transitions.default};
  height: ${({ height }) => height || 'auto'};
  
  &:hover {
    transform: ${({ hoverEffect }) => hoverEffect ? 'translateY(-5px)' : 'none'};
    box-shadow: ${({ theme, hoverEffect }) => hoverEffect ? theme.shadows.xl : ''};
  }
`;

const CardHeader = styled.div`
  padding: ${({ theme, padding }) => padding || `${theme.spacing.md} ${theme.spacing.lg}`};
  border-bottom: ${({ theme, noBorder }) => noBorder ? 'none' : `1px solid ${theme.colors.border}`};
`;

const CardBody = styled.div`
  padding: ${({ theme, padding }) => padding || `${theme.spacing.lg}`};
`;

const CardFooter = styled.div`
  padding: ${({ theme, padding }) => padding || `${theme.spacing.md} ${theme.spacing.lg}`};
  border-top: ${({ theme, noBorder }) => noBorder ? 'none' : `1px solid ${theme.colors.border}`};
`;

const CardImage = styled.div`
  width: 100%;
  height: ${({ height }) => height || '200px'};
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
`;

const Card = ({ 
  children,
  header,
  footer,
  image,
  imageHeight,
  bgColor,
  elevation = 'medium',
  height,
  hoverEffect = true,
  headerPadding,
  bodyPadding,
  footerPadding,
  headerNoBorder = false,
  footerNoBorder = false,
  ...props
}) => {
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.4 }
    }
  };

  return (
    <CardContainer 
      bgColor={bgColor}
      elevation={elevation}
      height={height}
      hoverEffect={hoverEffect}
      variants={cardVariants}
      {...props}
    >
      {image && (
        <CardImage src={image} height={imageHeight} />
      )}
      
      {header && (
        <CardHeader padding={headerPadding} noBorder={headerNoBorder}>
          {header}
        </CardHeader>
      )}
      
      <CardBody padding={bodyPadding}>
        {children}
      </CardBody>
      
      {footer && (
        <CardFooter padding={footerPadding} noBorder={footerNoBorder}>
          {footer}
        </CardFooter>
      )}
    </CardContainer>
  );
};

export default Card;
