import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const TicTacToeContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
`;

const GameContainer = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  padding: ${({ theme }) => theme.spacing.xl};
  box-shadow: ${({ theme }) => theme.shadows.lg};
  max-width: 400px;
  width: 100%;
  text-align: center;
`;

const GameTitle = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.primary};
`;

const GameSubtitle = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  color: ${({ theme }) => theme.colors.textLight};
`;

const Board = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const Cell = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease;
  color: ${({ player, theme }) => 
    player === 'X' ? theme.colors.primary : 
    player === 'O' ? theme.colors.secondary : 
    'transparent'};
  
  &:hover {
    background-color: ${({ theme, disabled }) => 
      disabled ? theme.colors.backgroundDark : theme.colors.backgroundMedium};
  }
`;

const GameStatus = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  font-weight: bold;
  min-height: 1.5rem;
  color: ${({ winner, theme }) => 
    winner === 'X' ? theme.colors.primary : 
    winner === 'O' ? theme.colors.secondary : 
    winner === 'draw' ? theme.colors.textLight : 
    theme.colors.text};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.md};
`;

const Button = styled.button`
  background-color: ${({ theme, primary }) => primary ? theme.colors.primary : theme.colors.backgroundDark};
  color: ${({ theme, primary }) => primary ? '#fff' : theme.colors.text};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${({ theme, primary }) => 
      primary ? theme.colors.primaryDark : theme.colors.backgroundMedium};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const TicTacToe = ({ onClose }) => {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [isXNext, setIsXNext] = useState(true);
  const [winner, setWinner] = useState(null);
  const [gameStatus, setGameStatus] = useState('Your turn (X)');
  const [aiThinking, setAiThinking] = useState(false);
  
  const calculateWinner = (squares) => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        return squares[a];
      }
    }
    
    // Check for draw
    if (squares.every(square => square !== null)) {
      return 'draw';
    }
    
    return null;
  };
  
  const handleClick = (i) => {
    if (winner || board[i] || aiThinking) return;
    
    const newBoard = [...board];
    newBoard[i] = 'X';
    setBoard(newBoard);
    setIsXNext(false);
    
    const gameWinner = calculateWinner(newBoard);
    if (gameWinner) {
      setWinner(gameWinner);
      setGameStatus(gameWinner === 'draw' ? "It's a draw!" : `Winner: ${gameWinner}`);
      return;
    }
    
    setGameStatus('AI is thinking...');
    setAiThinking(true);
  };
  
  const aiMove = () => {
    // Simple AI: first try to win, then block, then take center, then random
    const newBoard = [...board];
    
    // Check for winning move
    for (let i = 0; i < 9; i++) {
      if (!newBoard[i]) {
        newBoard[i] = 'O';
        if (calculateWinner(newBoard) === 'O') {
          setBoard(newBoard);
          setWinner('O');
          setGameStatus('Winner: O');
          setAiThinking(false);
          return;
        }
        newBoard[i] = null;
      }
    }
    
    // Check for blocking move
    for (let i = 0; i < 9; i++) {
      if (!newBoard[i]) {
        newBoard[i] = 'X';
        if (calculateWinner(newBoard) === 'X') {
          newBoard[i] = 'O';
          setBoard(newBoard);
          setIsXNext(true);
          setGameStatus('Your turn (X)');
          setAiThinking(false);
          return;
        }
        newBoard[i] = null;
      }
    }
    
    // Take center if available
    if (!newBoard[4]) {
      newBoard[4] = 'O';
      setBoard(newBoard);
      setIsXNext(true);
      setGameStatus('Your turn (X)');
      setAiThinking(false);
      return;
    }
    
    // Take a random empty cell
    const emptyCells = newBoard.map((cell, index) => cell === null ? index : null).filter(cell => cell !== null);
    if (emptyCells.length > 0) {
      const randomIndex = emptyCells[Math.floor(Math.random() * emptyCells.length)];
      newBoard[randomIndex] = 'O';
      setBoard(newBoard);
      
      const gameWinner = calculateWinner(newBoard);
      if (gameWinner) {
        setWinner(gameWinner);
        setGameStatus(gameWinner === 'draw' ? "It's a draw!" : `Winner: ${gameWinner}`);
      } else {
        setIsXNext(true);
        setGameStatus('Your turn (X)');
      }
    }
    
    setAiThinking(false);
  };
  
  useEffect(() => {
    if (!isXNext && !winner) {
      const timer = setTimeout(() => {
        aiMove();
      }, 700); // Add a small delay to simulate thinking
      
      return () => clearTimeout(timer);
    }
  }, [isXNext, winner, aiMove]);
  
  const resetGame = () => {
    setBoard(Array(9).fill(null));
    setIsXNext(true);
    setWinner(null);
    setGameStatus('Your turn (X)');
  };
  
  const renderCell = (i) => {
    return (
      <Cell 
        onClick={() => handleClick(i)} 
        player={board[i]}
        disabled={board[i] !== null || winner !== null || aiThinking}
      >
        {board[i]}
      </Cell>
    );
  };
  
  return (
    <AnimatePresence>
      <TicTacToeContainer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <CloseButton onClick={onClose}>✕</CloseButton>
        <GameContainer
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ type: 'spring', damping: 15 }}
        >
          <GameTitle>Tic Tac Toe</GameTitle>
          <GameSubtitle>You found the easter egg! Play a quick game?</GameSubtitle>
          
          <Board>
            {renderCell(0)}
            {renderCell(1)}
            {renderCell(2)}
            {renderCell(3)}
            {renderCell(4)}
            {renderCell(5)}
            {renderCell(6)}
            {renderCell(7)}
            {renderCell(8)}
          </Board>
          
          <GameStatus winner={winner}>{gameStatus}</GameStatus>
          
          <ButtonContainer>
            <Button onClick={resetGame}>New Game</Button>
            <Button primary onClick={onClose}>Close</Button>
          </ButtonContainer>
        </GameContainer>
      </TicTacToeContainer>
    </AnimatePresence>
  );
};

export default TicTacToe;
