import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import Button from './Button';

const LoadingContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, ${({ theme }) => theme.colors.backgroundDark} 0%, transparent 70%);
    opacity: 0.7;
  }
`;

const NameContainer = styled.div`
  font-family: ${({ theme }) => theme.fonts.tertiary};
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 2.5rem;
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 1.8rem;
  }
`;

const Character = styled(motion.span)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  margin: 0 0.05rem;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  
  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.primary};
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }
  
  &.revealed::after {
    transform: scaleX(1);
  }
`;

const BinaryContainer = styled(motion.div)`
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  margin-bottom: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.code};
  color: ${({ theme }) => theme.colors.textLight};
  opacity: 0.7;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 90%;
  position: relative;
  z-index: 2;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 1rem;
    letter-spacing: 0.05rem;
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 0.8rem;
    letter-spacing: 0.02rem;
  }
`;

const BinaryBit = styled(motion.span)`
  display: inline-block;
  margin: 0 0.05rem;
  color: inherit;
  transition: color 0.2s cubic-bezier(0.16, 1, 0.3, 1), font-weight 0.2s cubic-bezier(0.16, 1, 0.3, 1);
`;

const NameRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  position: relative;
  z-index: 2;
`;

const EnterButton = styled(motion.div)`
  margin-top: 3rem;
  position: relative;
  z-index: 2;
`;



const LoadingScreen = ({ onComplete }) => {
  // The actual binary representation of "robby moseley"
  const binaryName = useMemo(() => [
    "01110010", // r
    "01101111", // o
    "01100010", // b
    "01100010", // b
    "01111001", // y
    "01101101", // m
    "01101111", // o
    "01110011", // s
    "01100101", // e
    "01101100", // l
    "01100101", // e
    "01111001"  // y
  ], []);
  
  // Flatten the binary into individual bits for wave animation
  const flatBinary = useMemo(() => binaryName.join('').split(''), [binaryName]);
  
  const actualName = "robbymoseley";
  const [nameChars, setNameChars] = useState([]);
  const [binaryVisible, setBinaryVisible] = useState(true);
  const [highlightedBitIndex, setHighlightedBitIndex] = useState(-1);
  const [showButton, setShowButton] = useState(false);
  
  // Initialize with binary representation
  useEffect(() => {
    const initialChars = actualName.split('').map((char, index) => ({
      char: char,
      binary: binaryName[index],
      revealed: false
    }));
    
    setNameChars(initialChars);
  }, [actualName, binaryName]);
  
  // Animation sequence
  useEffect(() => {
    if (nameChars.length === 0) return;
    
    // Start the animation sequence after a shorter delay
    const startTimeout = setTimeout(() => {
      // First animate through all binary bits in a wave
      const totalBits = flatBinary.length;
      const bitAnimationSpeed = 30; // milliseconds per bit
      
      // Create a sequence of timeouts to highlight each bit in sequence
      const bitSequence = [];
      for (let i = 0; i < totalBits; i++) {
        const timeout = setTimeout(() => {
          setHighlightedBitIndex(i);
          
          // When we reach the end of a character's binary (every 8 bits),
          // reveal that character after a short delay
          if ((i + 1) % 8 === 0) {
            const charIndex = Math.floor(i / 8);
            setTimeout(() => {
              if (charIndex < actualName.length) {
                setNameChars(prev => 
                  prev.map((item, idx) => 
                    idx === charIndex ? { ...item, revealed: true } : item
                  )
                );
              }
              
              // Check if all characters are revealed
              if (charIndex === actualName.length - 1) {
                setTimeout(() => {
                  // Add a longer delay before hiding the binary
                  setTimeout(() => {
                    setBinaryVisible(false);
                    setTimeout(() => setShowButton(true), 300);
                  }, 1000); // 1 second delay to appreciate the fully highlighted binary
                }, 300);
              }
            }, 100);
          }
        }, 500 + (i * bitAnimationSpeed));
        
        bitSequence.push(timeout);
      }
      
      return () => {
        bitSequence.forEach(timeout => clearTimeout(timeout));
      };
    }, 600); // Reduced initial delay before starting the sequence
    
    return () => {
      clearTimeout(startTimeout);
    };
  }, [nameChars.length, actualName, flatBinary, binaryName]);
  
  return (
    <AnimatePresence>
      <LoadingContainer
        initial={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
      >
        <BinaryContainer>
          {flatBinary.map((bit, bitIndex) => {
            const isHighlighted = bitIndex <= highlightedBitIndex;
            const isGroupStart = bitIndex % 8 === 0;
            
            return (
              <BinaryBit
                key={`bit-${bitIndex}`}
                style={{ 
                  color: isHighlighted ? '#000000' : 'inherit',
                  fontWeight: isHighlighted ? 'bold' : 'normal',
                  marginLeft: isGroupStart && bitIndex > 0 ? '0.5rem' : '0.05rem'
                }}
                animate={{ 
                  opacity: binaryVisible ? (isHighlighted ? 1 : 0.85) : 0
                }}
                transition={{ duration: 0.2 }}
              >
                {bit}
              </BinaryBit>
            );
          })}
        </BinaryContainer>
        
        <NameContainer>
          <NameRow>
            {nameChars.map((item, index) => (
              <Character
                key={`char-${index}`}
                className={item.revealed ? 'revealed' : ''}
                initial={{ opacity: 0 }}
                animate={{ 
                  opacity: item.revealed ? 1 : 0,
                  scale: item.revealed ? 1 : 0.8,
                  y: item.revealed ? 0 : 10
                }}
                transition={{ 
                  duration: 0.3,
                  ease: 'easeOut'
                }}
              >
                {item.char}
              </Character>
            ))}
          </NameRow>
        </NameContainer>
        
        <AnimatePresence>
          {showButton && (
            <EnterButton
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, ease: 'easeOut' }}
            >
              <Button 
                onClick={onComplete}
                size="large"
                variant="primary"
              >
                Enter Site
              </Button>
            </EnterButton>
          )}
        </AnimatePresence>
      </LoadingContainer>
    </AnimatePresence>
  );
};

export default LoadingScreen;
